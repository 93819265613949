export const ordenVenta = {
    "ordenVenta": [
        {
            "id": 1,
            "fecha": "30 jul 2024",
            "orden_de_venta": "OVKT005689",
            "numero_referencia": "EST0024054 - OC035663",
            "nombre_cliente": "FERMAR SAS",
            "estado": "CONFIRMADO",
            "facturada": false,
            "pago": false,
            "importe": "COP25,248,863.00",
            "fecha_envio_esperada": "16 sep 2024",
            "estado_pedido": "Vence en 46 días",
            "metodo_entrega": "TRANSPORTADORA"
        },
        {
            "id": 2,
            "fecha": "30 jul 2024",
            "orden_de_venta": "OVKT005690",
            "numero_referencia": "EST0024090 - OC94923",
            "nombre_cliente": "SIDERURGICA DEL OCCIDENTE S.A.S. SIDOC S.A.S.",
            "estado": "APROBADO",
            "facturada": false,
            "pago": false,
            "importe": "COP4,032,279.00",
            "fecha_envio_esperada": "31 jul 2024",
            "estado_pedido": "",
            "metodo_entrega": "TRANSPORTADORA"
        },
        {
            "id": 3,
            "fecha": "26 jul 2024",
            "orden_de_venta": "OVKT005688",
            "numero_referencia": "EST0024105-OC1646",
            "nombre_cliente": "EUSSE SEGURIDAD",
            "estado": "APROBADO",
            "facturada": false,
            "pago": false,
            "importe": "COP4,383,246.00",
            "fecha_envio_esperada": "02 ago 2024",
            "estado_pedido": "",
            "metodo_entrega": "TRANSPORTADORA"
        },
        {
            "id": 4,
            "fecha": "26 jul 2024",
            "orden_de_venta": "OVKT005687",
            "numero_referencia": "EST0024083-OC193-2024",
            "nombre_cliente": "FIRE SERVICES SAS",
            "estado": "APROBADO",
            "facturada": false,
            "pago": false,
            "importe": "COP375,978.00",
            "fecha_envio_esperada": "30 jul 2024",
            "estado_pedido": "",
            "metodo_entrega": "TRANSPORTADORA"
        },
        {
            "id": 5,
            "fecha": "25 jul 2024",
            "orden_de_venta": "OVKT005686",
            "numero_referencia": "EST0024078 - OC4515975887",
            "nombre_cliente": "WEG COLOMBIA S.A.S.",
            "estado": "APROBACIÓN PENDIENTE",
            "facturada": false,
            "pago": false,
            "importe": "COP875,035.00",
            "fecha_envio_esperada": "30 jul 2024",
            "estado_pedido": "",
            "metodo_entrega": "TRANSPORTADORA"
        },
        {
            "id": 6,
            "fecha": "22 jul 2024",
            "orden_de_venta": "OVKT005684",
            "numero_referencia": "EST0024057-OC 309-2024",
            "nombre_cliente": "SERVITEC INGENIEROS SAS",
            "estado": "ANULADO",
            "facturada": false,
            "pago": false,
            "importe": "COP3,144,955.00",
            "fecha_envio_esperada": "18 sep 2024",
            "estado_pedido": "",
            "metodo_entrega": "TRANSPORTADORA"
        },
        {
            "id": 7,
            "fecha": "19 jul 2024",
            "orden_de_venta": "OVKT005683",
            "numero_referencia": "EST0024056",
            "nombre_cliente": "TERMOTASAJERO DOS S.A.E.S.P",
            "estado": "BORRADOR",
            "facturada": false,
            "pago": false,
            "importe": "COP3,036,285.00",
            "fecha_envio_esperada": "",
            "estado_pedido": "",
            "metodo_entrega": "TRANSPORTADORA"
        },
        {
            "id": 8,
            "fecha": "17 jul 2024",
            "orden_de_venta": "OVKT005682",
            "numero_referencia": "EST0024069-OC 2415524",
            "nombre_cliente": "INDUSTRIAS CTS S.A.S.",
            "estado": "CONFIRMADO",
            "facturada": false,
            "pago": false,
            "importe": "COP14,274,682.00",
            "fecha_envio_esperada": "18 jul 2024",
            "estado_pedido": "Vencido por 14 días",
            "metodo_entrega": "TRANSPORTADORA"
        },
        {
            "id": 9,
            "fecha": "17 jul 2024",
            "orden_de_venta": "OVKT005681",
            "numero_referencia": "EST0024072-OC 4515896658",
            "nombre_cliente": "WEG COLOMBIA S.A.S",
            "estado": "CONFIRMADO",
            "facturada": false,
            "pago": false,
            "importe": "COP9,861,480.00",
            "fecha_envio_esperada": "27 ago 2024",
            "estado_pedido": "Vence en 26 días",
            "metodo_entrega": "TRANSPORTADORA"
        },
        {
            "id": 10,
            "fecha": "15 jul 2024",
            "orden_de_venta": "OVKT005680",
            "numero_referencia": "EST0024063-OC1056",
            "nombre_cliente": "REPRESENTACIONES QUIMICAS DE COLOMBIA Y SOLUCIONES SAS",
            "estado": "CERRADO",
            "facturada": false,
            "pago": false,
            "importe": "COP1,815,429.78",
            "fecha_envio_esperada": "16 jul 2024",
            "estado_pedido": "",
            "metodo_entrega": "TRANSPORTADORA"
        },
        {
            "id": 11,
            "fecha": "13 jul 2024",
            "orden_de_venta": "OVKT005679",
            "numero_referencia": "EST0023885-OC4500008544",
            "nombre_cliente": "SLA COL S A S",
            "estado": "CONFIRMADO",
            "facturada": false,
            "pago": false,
            "importe": "COP27,562,304.00",
            "fecha_envio_esperada": "28 oct 2024",
            "estado_pedido": "Vence en 88 días",
            "metodo_entrega": "TRANSPORTADORA"
        },
        {
            "id": 12,
            "fecha": "12 jul 2024",
            "orden_de_venta": "OVKT005678",
            "numero_referencia": "EST0024050-OC 4508147588",
            "nombre_cliente": "SCHNEIDER ELECTRIC DE COLOMBIA S.A.S.",
            "estado": "CONFIRMADO",
            "facturada": false,
            "pago": false,
            "importe": "COP895,235.00",
            "fecha_envio_esperada": "28 oct 2024",
            "estado_pedido": "",
            "metodo_entrega": "TRANSPORTADORA"
        },
        {
            "id": 13,
            "fecha": "02 jul 2024",
            "orden_de_venta": "OVKT005677",
            "numero_referencia": "EST0024045-OC 9744",
            "nombre_cliente": "GIM INGENIERIA ELECTRICA LTDA",
            "estado": "CERRADO",
            "facturada": false,
            "pago": false,
            "importe": "COP158,389.00",
            "fecha_envio_esperada": "15 jul 2024",
            "estado_pedido": "",
            "metodo_entrega": "CLIENTE RECOGE"
        },

        // Duplicates added
        {
            "id": 14,
            "fecha": "30 jul 2024",
            "orden_de_venta": "OVKT005689",
            "numero_referencia": "EST0024054 - OC035663",
            "nombre_cliente": "FERMAR SAS",
            "estado": "CONFIRMADO",
            "facturada": false,
            "pago": false,
            "importe": "COP25,248,863.00",
            "fecha_envio_esperada": "16 sep 2024",
            "estado_pedido": "Vence en 46 días",
            "metodo_entrega": "TRANSPORTADORA"
        },
        {
            "id": 15,
            "fecha": "30 jul 2024",
            "orden_de_venta": "OVKT005690",
            "numero_referencia": "EST0024090 - OC94923",
            "nombre_cliente": "SIDERURGICA DEL OCCIDENTE S.A.S. SIDOC S.A.S.",
            "estado": "APROBADO",
            "facturada": false,
            "pago": false,
            "importe": "COP4,032,279.00",
            "fecha_envio_esperada": "31 jul 2024",
            "estado_pedido": "",
            "metodo_entrega": "TRANSPORTADORA"
        },
        {
            "id": 16,
            "fecha": "26 jul 2024",
            "orden_de_venta": "OVKT005688",
            "numero_referencia": "EST0024105-OC1646",
            "nombre_cliente": "EUSSE SEGURIDAD",
            "estado": "APROBADO",
            "facturada": false,
            "pago": false,
            "importe": "COP4,383,246.00",
            "fecha_envio_esperada": "02 ago 2024",
            "estado_pedido": "",
            "metodo_entrega": "TRANSPORTADORA"
        },
        {
            "id": 17,
            "fecha": "26 jul 2024",
            "orden_de_venta": "OVKT005687",
            "numero_referencia": "EST0024083-OC193-2024",
            "nombre_cliente": "FIRE SERVICES SAS",
            "estado": "APROBADO",
            "facturada": false,
            "pago": false,
            "importe": "COP375,978.00",
            "fecha_envio_esperada": "30 jul 2024",
            "estado_pedido": "",
            "metodo_entrega": "TRANSPORTADORA"
        },
        {
            "id": 18,
            "fecha": "25 jul 2024",
            "orden_de_venta": "OVKT005686",
            "numero_referencia": "EST0024078 - OC4515975887",
            "nombre_cliente": "WEG COLOMBIA S.A.S.",
            "estado": "APROBACIÓN PENDIENTE",
            "facturada": false,
            "pago": false,
            "importe": "COP875,035.00",
            "fecha_envio_esperada": "30 jul 2024",
            "estado_pedido": "",
            "metodo_entrega": "TRANSPORTADORA"
        },
        {
            "id": 19,
            "fecha": "22 jul 2024",
            "orden_de_venta": "OVKT005684",
            "numero_referencia": "EST0024057-OC 309-2024",
            "nombre_cliente": "SERVITEC INGENIEROS SAS",
            "estado": "ANULADO",
            "facturada": false,
            "pago": false,
            "importe": "COP3,144,955.00",
            "fecha_envio_esperada": "18 sep 2024",
            "estado_pedido": "",
            "metodo_entrega": "TRANSPORTADORA"
        },
        {
            "id": 20,
            "fecha": "19 jul 2024",
            "orden_de_venta": "OVKT005683",
            "numero_referencia": "EST0024056",
            "nombre_cliente": "TERMOTASAJERO DOS S.A.E.S.P",
            "estado": "BORRADOR",
            "facturada": false,
            "pago": false,
            "importe": "COP3,036,285.00",
            "fecha_envio_esperada": "",
            "estado_pedido": "",
            "metodo_entrega": "TRANSPORTADORA"
        },
        {
            "id": 21,
            "fecha": "17 jul 2024",
            "orden_de_venta": "OVKT005682",
            "numero_referencia": "EST0024069-OC 2415524",
            "nombre_cliente": "INDUSTRIAS CTS S.A.S.",
            "estado": "CONFIRMADO",
            "facturada": false,
            "pago": false,
            "importe": "COP14,274,682.00",
            "fecha_envio_esperada": "18 jul 2024",
            "estado_pedido": "Vencido por 14 días",
            "metodo_entrega": "TRANSPORTADORA"
        },
        {
            "id": 22,
            "fecha": "17 jul 2024",
            "orden_de_venta": "OVKT005681",
            "numero_referencia": "EST0024072-OC 4515896658",
            "nombre_cliente": "WEG COLOMBIA S.A.S",
            "estado": "CONFIRMADO",
            "facturada": false,
            "pago": false,
            "importe": "COP9,861,480.00",
            "fecha_envio_esperada": "27 ago 2024",
            "estado_pedido": "Vence en 26 días",
            "metodo_entrega": "TRANSPORTADORA"
        },
        {
            "id": 23,
            "fecha": "15 jul 2024",
            "orden_de_venta": "OVKT005680",
            "numero_referencia": "EST0024063-OC1056",
            "nombre_cliente": "REPRESENTACIONES QUIMICAS DE COLOMBIA Y SOLUCIONES SAS",
            "estado": "CERRADO",
            "facturada": false,
            "pago": false,
            "importe": "COP1,815,429.78",
            "fecha_envio_esperada": "16 jul 2024",
            "estado_pedido": "",
            "metodo_entrega": "TRANSPORTADORA"
        },
        {
            "id": 24,
            "fecha": "13 jul 2024",
            "orden_de_venta": "OVKT005679",
            "numero_referencia": "EST0023885-OC4500008544",
            "nombre_cliente": "SLA COL S A S",
            "estado": "CONFIRMADO",
            "facturada": false,
            "pago": false,
            "importe": "COP27,562,304.00",
            "fecha_envio_esperada": "28 oct 2024",
            "estado_pedido": "Vence en 88 días",
            "metodo_entrega": "TRANSPORTADORA"
        },
        {
            "id": 25,
            "fecha": "12 jul 2024",
            "orden_de_venta": "OVKT005678",
            "numero_referencia": "EST0024050-OC 4508147588",
            "nombre_cliente": "SCHNEIDER ELECTRIC DE COLOMBIA S.A.S.",
            "estado": "CONFIRMADO",
            "facturada": false,
            "pago": false,
            "importe": "COP895,235.00",
            "fecha_envio_esperada": "28 oct 2024",
            "estado_pedido": "",
            "metodo_entrega": "TRANSPORTADORA"
        },
        {
            "id": 26,
            "fecha": "02 jul 2024",
            "orden_de_venta": "OVKT005677",
            "numero_referencia": "EST0024045-OC 9744",
            "nombre_cliente": "GIM INGENIERIA ELECTRICA LTDA",
            "estado": "CERRADO",
            "facturada": false,
            "pago": false,
            "importe": "COP158,389.00",
            "fecha_envio_esperada": "15 jul 2024",
            "estado_pedido": "",
            "metodo_entrega": "CLIENTE RECOGE"
        }
    ]
};
