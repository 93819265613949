import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import { DataProveder } from './contex/DataContext';
import { DataProvederPaginacion } from './contex/DataContexPaginacion';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <DataProveder>
      <DataProvederPaginacion>
        <BrowserRouter>
            <App />
          </BrowserRouter>
      </DataProvederPaginacion>
  </DataProveder>
);


