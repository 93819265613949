import React, {createContext, useState, useEffect} from "react";

export const DataContextPaginacion = createContext({});

export const DataProvederPaginacion = ({children}) => {

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(5);

    const handlePageChange = (value, totalPage) => {
        if (value === "&laquo;" || value === "...") {
            setPage(1);
        } else if (value === "&lsaquo;") {
            if (page !== 1) {
                setPage(page - 1);
            }
        } else if (value === "&rsaquo;") {
            if (page !== totalPage) {
                setPage(page + 1);
            }
        } else if (value === "&raquo;" || value === "...") {
            setPage(totalPage);
        } else {
            setPage(value);
        }
    };



    return(
        <DataContextPaginacion.Provider value={{page, setPage, limit, setLimit, handlePageChange}}>
            {children}
        </DataContextPaginacion.Provider>
    )

}