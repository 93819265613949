import React, { useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import "../styles/login.css";

export const Login = () => {
    const [credentials, setCredentials] = useState({
        Email: '',
        Password: ''
    });

    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const history = useNavigate();

    const handleChange = (e) => {
        setCredentials({
        ...credentials,
        [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!credentials.Email || !credentials.Password) {
        setErrorMessage('Por favor ingrese su correo y contraseña');
        return;
        }

        // Credenciales de prueba
        // const validEmail = 'anabel@test.com';
        // const validPassword = '123';
        // const validOrganizationID = '123456';

        setIsLoading(true);
        
        // localStorage.setItem('organizationID', validOrganizationID);

        await new Promise((resolve) => setTimeout(resolve, 2000));
        console.log("Email: ", credentials.Email)
        console.log("Password: ", credentials.Password)
        // history('/store');

        // Conexión con la API http://localhost:8080/ep_UserEcart/Login, https://facturacionei3.com/ep_UserEcart/Login
        try {
            const response = await axios.post('https://facturacionei3.com/ep_UserEcart/Login', {
                Email: credentials.Email,
                Password: credentials.Password
            });

            

            if (response.status === 200) {
            localStorage.setItem('organizationID', response.data);
            
            history('/store');
            } else {
                setErrorMessage('Credenciales incorrectas');
            }
        } catch (error) {
            setIsLoading(false);
            console.error('Error:', error);
            setErrorMessage('Ocurrió un error. Por favor, intente nuevamente');
        }
    };

    return (
        <div className="login_container">
            <div className="login_subContainer">
                <h2>Login</h2>
                    <form className="login_form" onSubmit={handleSubmit}>
                        <div className="mb-1 wide">
                            <label htmlFor="Email" className="form-label">Credencial</label>
                            <input 
                                type="text" 
                                className="form-control" 
                                id="Email" 
                                name="Email" 
                                value={credentials.Email} 
                                onChange={handleChange} 
                                required 
                                
                            />
                        </div>
                        <div className="mb-1 wide">
                            <label htmlFor="Password" className="form-label">Contraseña</label>
                            <input 
                                type="password" 
                                className="form-control" 
                                id="Password" 
                                name="Password" 
                                value={credentials.Password} 
                                onChange={handleChange} 
                                required 
                                
                            />
                    </div>
                    <Link to="/recoverPassword" className="form-text">¿Olvidaste tu contraseña?</Link>

                    <button type="submit" className="btn btn-primary btn-lg mt-3" disabled={isLoading}>
                        {isLoading ? <span className="password-recovery-spinner"></span> : 'Iniciar sesión'}
                    </button>

                    {/* <button type="submit" className="btn btn-primary btn-lg mt-3"></button> */}

                    {errorMessage && <div className="alert alert-danger mt-3">{errorMessage}</div>}
                </form>

                <p className="login_register mt-3">
                    Si no tiene cuenta <Link to="/register">Regístrate</Link>
                </p>
            </div>
        </div>
    );
};