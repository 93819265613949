export const storeColors = {
    "Allegro": "#fc5b04",
    "Amazon": "#f99d0d",
    "Bling":"#5cc484",
    "Bigcommerce": "#4c4c4c",
    "Claroshop": "#fa1a0b",
    "Cscart": "#06dcfc",
    "Ebay":"#e1343a",
    "Ecwid": "#0484cb",
    "Ecart": "#65a533",
    "Etsy": "#f47424",
    "Hybris": "#2d5aa2",
    "Jumpseller": "#9cc53a",
    "Kometia":"#6269f7",
    "Linio": "#ee830e",
    "Liverpool": "#e6428d",
    "Loja":"#2cc4c4",
    "Magento1": "#f68f2c",
    "Magento2":"#fc6c04",
    "MercadoLibre": "#fae308",
    "Multivende": "#4ebfab",
    "Nopcommerce": "#24cae2",
    "Opencart": "#12bbf0",
    "Prestashop": "#bc1a65",
    "Quickbooks": "#34a026",
    "Salesforce":"#04a3e2",
    "Shein":"#040404",
    "Shiphero":"#ec5454",
    "Shoplazza":"#ff0200",
    "Squarespace":"#050505",
    "TiendaNuve":"#2b3354",
    "Tiny":"#0c2bd1",
    "Unicommerce": "#1b92ba",
    "Vendhq": "#43bb5b",
    "Verskis": "#db8742",
    "Vtex": "#f41c63",
    "Walmart": "#047bc2",
    "Woocommerce": "#9b5c8c",
    "Yampi": "#7b1f82",
    "Zoho": "#e12b2d"
};
