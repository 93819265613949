import React, { useState, useEffect} from 'react';
import axios from 'axios';
import { FaEdit } from "react-icons/fa";
import '../styles/profile.css';

export const Profile = () => {
    const [isEditing, setIsEditing] = useState(false);
    const [message, setMessage] = useState('');

    /*name Anabel, email test@test.com, password xxxx */
    const [formData, setFormData] = useState({
        Name: '',
        Email: '',
        OrganizationID: '',
    });

    const handleEditClick = () => {
        setIsEditing(!isEditing);
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };


    const traerDatos = async () => {
        const OrganizationID = localStorage.getItem('organizationID')

        

        const response = await axios.get(`https://facturacionei3.com/ep_UserEcart/GetProfile?OrganizationID=${OrganizationID}`);


    
        if(response.status === 200){
            const data = {
                Name: response.data.Name,
                Email: response.data.Email,
                OrganizationID: response.data.OrganizationID
            }
    
            setFormData(data)
        }else{
            console.log('Error al obtener el perfil:', response.data);
    
        }
    }

    useEffect(()  => {
        traerDatos()

    }, [])

    const handleSaveClick = async () => {
        
        if (!formData.Name || !formData.Email || !formData.OrganizationID) {
            setMessage('Por favor complete todos los campos.');
            return;
        }

        // Aquí va la lógica para guardar los cambios
        
        try {
            const response = await axios.put('https://facturacionei3.com/ep_UserEcart/EditProfile', formData, {
                headers: {
                    'Content-Type': 'application/json',
                },
                
            }, {

                OrganizationID: formData.OrganizationID,
                Email: formData.Email,
                Name: formData.Name,

            }

        );

        console.log(response)
            if (response.status === 200) {
                setMessage('Datos actualizados correctamente.');
            } else {
                setMessage('Hubo un error al actualizar los datos.');
            }
        } catch (error) {
            console.error('Error al actualizar el perfil:', error);
            setMessage('Hubo un error al actualizar los datos.');
        } finally {
            setIsEditing(false);
        }
        

        

        setMessage('Datos actualizados correctamente.');
        setIsEditing(false);
        

    };
    setTimeout(() => setMessage(''), 5000)

    return (
        <div className='profile_container'>
            
            <div className='profile_container_h2'>
                <h2>Mi Perfil</h2>

                <div className='profile_btn'>
                    <button className="profile_btn_edit" onClick={handleEditClick}>
                        Editar <FaEdit size={15}  />
                    </button>
                </div>
            </div>

            <div className='profile_subcontainer'>

                <div className='profile_datos'>
                    <label>OrganizationID:</label>
                    <input
                        type="text"
                        name="OrganizationID"
                        value={formData.OrganizationID}
                        onChange={handleChange}
                        disabled={true}
                    />

                    <label>Name:</label>
                    <input
                        type="text"
                        name="Name"
                        value={formData.Name}
                        onChange={handleChange}
                        disabled={!isEditing}
                    />

                    <label>Email:</label>
                    <input
                        type="email"
                        name="Email"
                        value={formData.Email}
                        onChange={handleChange}
                        disabled={!isEditing}
                    />

                    
                    {/* <label>Password:</label>
                    <input
                        type="password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        disabled={!isEditing}
                    /> */}
                </div>
                
                

            </div>
            {isEditing && (
                <button className="save-button" onClick={handleSaveClick}>
                    Cambiar
                </button>
            )}

            {message && <p className="message">{message}</p>}
        </div>
    );
};
