import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import "../styles/navbar.css";
import logo from '../img/logo/logo blanco_sinborde.png';
import { MdExitToApp, MdAccountCircle } from "react-icons/md";
import { IoIosSettings } from "react-icons/io";
import { IoMenuSharp } from "react-icons/io5";

export const Navbar = () => {
    const history = useNavigate();
    const isLoggedIn = localStorage.getItem('organizationID');
    const [showDropdown, setShowDropdown] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);

    const handleLogout = () => {
        localStorage.removeItem('organizationID');
        localStorage.removeItem('organizationID');
        history('/');
    };

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
        setMenuOpen(!menuOpen);
    };

    return (
        <div className='navbar_container'>
            <div className='navbar_logo'>
                <img src={logo} alt="Logo" />   
            </div>
            <div className={`navbar_link ${showDropdown ? 'show' : ''} ${isLoggedIn ? '' : 'navbar_link_login'}`}>
                {isLoggedIn ? (
                    <div className='navbar_link_grid'>
                        {/* <Link to="/store" className="navbar_btn">Mis Tiendas</Link>
                        <Link to="/flujos" className="navbar_btn">Flujos</Link>
                        <Link to="/articles" className="navbar_btn">Artículos</Link> */}
                        <Link to="/profile" className="navbar_btn"><IoIosSettings size={22}/></Link>

                        <Link className="navbar_link_exit" to="/" onClick={handleLogout}>
                            <MdExitToApp size={28}/>
                        </Link>
                    </div>
                ) : (
                    <div className='navbar_link_grid_login'>
                        <Link to="/" className="navbar_btn">Login</Link>
                        <Link to="/register" className="navbar_btn">Regístrate</Link>
                    </div>
                )}
            </div>
            <IoMenuSharp className={`menu ${menuOpen ? 'open' : ''}`} size={30} onClick={toggleDropdown} />
        </div>
    );
};
