import React from 'react';
import logo from '../img/Logo ei3 original.jpg';
import '../styles/articuloInformaciónGeneral.css'

export const ArticuloInformaciónGeneral = () => {
    return (
        <div className='articuloInformaciónGeneral_subConatiner_title-informacionGeneral'>
            <div className='articuloInformaciónGeneral-informacionGeneral'>
                <div className="articuloInformaciónGeneral-informacionGeneral-form-left">

                    <div className='articuloInformaciónGeneral-informacionGeneral-form-left-grid'>
                        <label className='articuloInformaciónGeneral-label-claro'>Tipo de artículo </label>
                        <label >Artículos de venta</label>
                    </div>

                    <div className='articuloInformaciónGeneral-informacionGeneral-form-left-grid'>
                        <label className='articuloInformaciónGeneral-label-claro'>SKU (Código de artículo) </label>
                        <label>MKPLS-001</label>
                    </div>
                    
                    <div className='articuloInformaciónGeneral-informacionGeneral-form-left-grid'>
                        <label className='articuloInformaciónGeneral-label-claro'>Unidad </label>
                        <label >E48 - Servicio</label>
                    </div>

                    <div className='articuloInformaciónGeneral-informacionGeneral-form-left-grid'>
                        <label className='articuloInformaciónGeneral-label-claro'>Clave SAT</label>
                        <label>84111506</label>
                    </div>

                    <div className='articuloInformaciónGeneral-informacionGeneral-form-left-grid'>
                        <label className='articuloInformaciónGeneral-label-claro'>Objeto de Impuesto</label>
                        <label>02 Sí objeto de impuesto.</label>
                    </div>

                    <div className='articuloInformaciónGeneral-informacionGeneral-form-left-grid'>
                        <label className='articuloInformaciónGeneral-label-claro'>Es un Producto USD</label>
                        <label >false</label>
                    </div>

                    <div className='articuloInformaciónGeneral-informacionGeneral-form-left-grid'>
                        <label className='articuloInformaciónGeneral-label-claro'>Es un Producto USD</label>
                        <label>false</label>
                    </div>
                    
                    <div className='articuloInformaciónGeneral-informacionGeneral-form-left-grid'>
                        <label className='articuloInformaciónGeneral-label-claro'>Línea de Venta</label>
                        <label>Zoho Desarrollo</label>
                    </div>

                    <div className='articuloInformaciónGeneral-informacionGeneral-form-left-grid'>
                        <label className='articuloInformaciónGeneral-label-claro'>APP</label>
                        <label>Inventory</label>
                    </div>

                    <div className='articuloInformaciónGeneral-informacionGeneral-form-left-grid'>
                        <label className='articuloInformaciónGeneral-label-claro'>Tipo de ID Estandar</label>
                        <label>Estándar de adopción del contribuyente</label>
                    </div>

                    <div className='articuloInformaciónGeneral-informacionGeneral-form-left-grid'>
                        <label className='articuloInformaciónGeneral-label-claro'>ID Estandar</label>
                        <label>1</label>
                    </div>

                    <div className='articuloInformaciónGeneral-informacionGeneral-form-left-grid'>
                        <label className='articuloInformaciónGeneral-label-claro'>Origen creado</label>
                        <label>Usuario</label>
                    </div>

                    <div className='articuloInformaciónGeneral-informacionGeneral-form-left-grid'>
                        <label className='articuloInformaciónGeneral-label-claro'>Sales Tax</label>
                        <label>IVA [16%]</label>
                    </div>

                    <div className='articuloInformaciónGeneral-informacionGeneral-form-left'>
                        <h4>Información de ventas</h4>
                    </div>
                    <div className='articuloInformaciónGeneral-informacionGeneral-form-left-grid'>
                        <label className='articuloInformaciónGeneral-label-claro'>Precio de venta</label>
                        <label>MXN$65,000.00</label>
                    </div>

                    <div className='articuloInformaciónGeneral-informacionGeneral-form-left-grid'>
                        <label className='articuloInformaciónGeneral-label-claro'>Cuenta de ventas</label>
                        <label>Ingresos totales </label>
                    </div>

                </div>

                <div className="articuloInformaciónGeneral-informacionGeneral-form-right">
                    <div className="articuloInformaciónGeneral-informacionGeneral-image-preview">
                        <img src={logo} alt="Vista previa" className="preview-image" />
                </div>
                                            
            </div>
        </div>
    </div>
    )
}

