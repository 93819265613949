import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import '../styles/changePassword.css'

export function ChangePassword() {

    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState('');

    const [formData, setFormData] = useState({
        organizationID: '',
        password: '',
        confirmPassword: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const organizationID = params.get('OrganizationID');
    const oldPassword = params.get('OldPassword');

    useEffect(() => {
        if (!organizationID || !oldPassword) {
            setMessage('');
        }
    }, [organizationID, oldPassword]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setMessage('');

        if (formData.password !== formData.confirmPassword) {
            setMessage('Las contraseñas no coinciden.');
            setIsLoading(false);
            return;
        }

        // Simulo la llamada al backend
        await new Promise((resolve) => setTimeout(resolve, 2000));
        setMessage('La contraseña se ha cambiado correctamente.');

        
        /*try {
            const response = await axios.put('URL_DEL_ENDPOINT', {
                organizationID: formData.organizationID,
                password: formData.password,
                confirmPassword: formData.confirmPassword
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (response.status === 200) {
                setMessage('La contraseña se ha cambiado correctamente.');
            } else {
                setMessage('Hubo un error al cambiar la contraseña.');
            }
        } catch (error) {
            setMessage('Hubo un error al cambiar la contraseña.');
        }*/

        setIsLoading(false);
    };

    return (
        <div className="changePassword_container">
            <h2>Cambiar Contraseña</h2>
            <form className="changePassword_form" onSubmit={handleSubmit}>
                <label htmlFor="">OrganizationID</label>
                <input type="text"
                    id="organizationID"
                    name="organizationID"
                    value={formData.organizationID}
                    onChange={handleChange}
                    required
                    disabled={isLoading}
                />
                <label htmlFor="newPassword">Nueva Contraseña:</label>
                <input
                    type="password"
                    id="newPassword"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    required
                    disabled={isLoading}
                />
                <label htmlFor="confirmPassword">Confirmar Nueva Contraseña:</label>
                <input
                    type="password"
                    id="confirmPassword"
                    name='confirmPassword'
                    value={formData.confirmPassword}
                    onChange={handleChange}
                    required
                    disabled={isLoading}
                />
                <button type="submit" disabled={isLoading}>
                    {isLoading ? <span className="password-change-spinner"></span> : 'Enviar'}
                </button>
            </form>
            {message && <p className="message">{message}</p>}
        </div>
    )
}


