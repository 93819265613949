import _ from "lodash";

export const returnPaginationRange = (totalPage, page) => {
    if (totalPage <= 1) return [1];

    if (page <= 1) page = 1;
    if (page >= totalPage) page = totalPage;

    if (totalPage <= 2) {
        return _.range(1, totalPage + 1);
    }

    if (page === 1) {
        return [1, '...', totalPage];
    }

    if (page === totalPage) {
        return [1, '...', totalPage];
    }

    return [1, '...', page, '...', totalPage];
};

export const returnPaginationRangeMenu = (totalPage, page) => {
    if (totalPage <= 1) return [1];

    if (page <= 1) page = 1;
    if (page >= totalPage) page = totalPage;

    if (totalPage <= 2) {
        return _.range(1, totalPage + 1);
    }

    if (page === 1) {
        return [1, totalPage];
    }

    if (page === totalPage) {
        return [1, totalPage];
    }

    return [1, page, totalPage];
};
