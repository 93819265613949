import React from 'react';
import '../styles/articuloDetalladaTransacciones.css'

const ArticuloDetalladaTransacciones = () => {
    return (
        <div className='articuloDetalladaTransacciones_subConatiner_title-transacciones'>
            <div className='articuloDetalladaTransacciones_subConatiner_filter'>
                <div className='articuloDetalladaTransacciones_subConatiner_filter-info'>
                    <label >Filtrar por:</label>
                    <select name="" id="">
                        <option value="">Estimaciones</option>
                        <option value="">Órdenes de ventas</option>
                        <option value="">Facturas</option>
                        <option value="">Notas de crédito</option>
                        <option value="">Facturas recurrentes</option>
                        <option value="">Recibos de ventas</option>
                    </select>
                </div>
                <div className='articuloDetalladaTransacciones_subConatiner_filter-info'>
                    <label >Filtrar por:</label>
                    <select name="" id="">
                        <option value="">Todo</option>
                        <option value="">Borrador</option>
                        <option value="">Enviados</option>
                        <option value="">Visto por el cliente</option>
                        <option value="">Aceptados</option>
                        <option value="">Facrutado</option>
                        <option value="">Rechazó</option>
                        <option value="">Vencido</option>
                    </select>
                </div>

                
            </div>

            <div className='articuloDetalladaTransacciones_subConatiner_title-historia'>
                    <table className='articuloDetalladaTransacciones_subConatiner_tabla'>
                        <thead className='articuloDetalladaTransacciones_subConatiner_tabla_title'>
                            <tr>
                                <th className='ellipsis'>FECHA</th>
                                <th className='ellipsis'>NÚMERO DE ESTIMACIÓN</th>
                                <th className='ellipsis'>NOMBRE DEL CLIENTE</th>
                                <th className='ellipsis'>CANTIDAD VENDIDA</th>
                                <th className='ellipsis'>PRECIO</th>
                                <th className='ellipsis'>TOTAL</th>
                                <th className='ellipsis'>ESTADO</th>
                            </tr>
                        </thead>
                        
                        <tbody className='articuloDetalladaTransacciones_subConatiner_tabla_title-td'>
                            <tr>
                                <td>2024-08-07</td>
                                <td>Cotización-006707</td>
                                <td>Test EEIII333 DI</td>
                                <td>1.00</td>
                                <td>MXN$65,000.00</td>
                                <td>MXN$65,000.00</td>
                                <td>Enviado</td>
                            </tr>
                            <tr>
                                <td>2024-08-07</td>
                                <td>Cotización-006707</td>
                                <td>Test EEIII333 DI</td>
                                <td>1.00</td>
                                <td>MXN$65,000.00</td>
                                <td>MXN$65,000.00</td>
                                <td>Enviado</td>
                            </tr>
                            <tr>
                                <td>2024-08-07</td>
                                <td>Cotización-006707</td>
                                <td>Test EEIII333 DI</td>
                                <td>1.00</td>
                                <td>MXN$65,000.00</td>
                                <td>MXN$65,000.00</td>
                                <td>Enviado</td>
                            </tr>
                            <tr>
                                <td>2024-08-07</td>
                                <td>Cotización-006707</td>
                                <td>Test EEIII333 DI</td>
                                <td>1.00</td>
                                <td>MXN$65,000.00</td>
                                <td>MXN$65,000.00</td>
                                <td>Enviado</td>
                            </tr>
                            <tr>
                                <td>2024-08-07</td>
                                <td>Cotización-006707</td>
                                <td>Test EEIII333 DI</td>
                                <td>1.00</td>
                                <td>MXN$65,000.00</td>
                                <td>MXN$65,000.00</td>
                                <td>Enviado</td>
                            </tr>
                            <tr>
                                <td>2024-08-07</td>
                                <td>Cotización-006707</td>
                                <td>Test EEIII333 DI</td>
                                <td>1.00</td>
                                <td>MXN$65,000.00</td>
                                <td>MXN$65,000.00</td>
                                <td>Enviado</td>
                            </tr>
                            <tr>
                                <td>2024-08-07</td>
                                <td>Cotización-006707</td>
                                <td>Test EEIII333 DI</td>
                                <td>1.00</td>
                                <td>MXN$65,000.00</td>
                                <td>MXN$65,000.00</td>
                                <td>Enviado</td>
                            </tr>
                            
                            <tr>
                                <td>2024-08-07</td>
                                <td>Cotización-006707</td>
                                <td>Test EEIII333 DI</td>
                                <td>1.00</td>
                                <td>MXN$65,000.00</td>
                                <td>MXN$65,000.00</td>
                                <td>Enviado</td>
                            </tr>
                            <tr>
                                <td>2024-08-07</td>
                                <td>Cotización-006707</td>
                                <td>Test EEIII333 DI</td>
                                <td>1.00</td>
                                <td>MXN$65,000.00</td>
                                <td>MXN$65,000.00</td>
                                <td>Enviado</td>
                            </tr>
                            <tr>
                                <td>2024-08-07</td>
                                <td>Cotización-006707</td>
                                <td>Test EEIII333 DI</td>
                                <td>1.00</td>
                                <td>MXN$65,000.00</td>
                                <td>MXN$65,000.00</td>
                                <td>Enviado</td>
                            </tr>
                            
                            
                        </tbody>
                    </table>
                </div>
        </div>
    )
}

export default ArticuloDetalladaTransacciones
