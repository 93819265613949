export  const facturasData = {
    "facturas": [
        {
            "id":"1",
            "fecha_factura": "2022-02-09",
            "numero_factura": "A-TESTV4",
            "numero_orden": "",
            "nombre_cliente": "Servi Climas y Calefacciones Monterrey",
            "estado": "PAGADO",
            "fecha_vencimiento": "2022-02-09",
            "saldo_adeudado": "MXN$0.00",
            "sucursal": "Headquarters",
            "precio": "1.16",
            "sub_total": "MXN$1.00",
            "total": "MXN$1.00",
            "cantidad": "MXN$1.00",
            "saldo_adeudado": "MXN$1.00",
            "terminos": "Pagadero a la recepción",
            "vendedor": "Jose Ignacio Salcedo",
            "forma_pago": "01  Efectivo",
            "USO_CFDI": "G03 Gasto en General",
            "metodo_de_pago": "PPD PAGO PARRCIALIDADES",
            "exportacion": "01 No aplica",
            "medio_de_pago": "C0_ZZZ - Acuerdo mutuo",
            "modo_de_pago":"contado",
            "detalle_del_articulo": "Colombia Objeto Prueba",
            "localidad": "Monterrey Nuevo León 64620",
            "provincia":"Monterrey",
            "direccion": "Colon 1014Col. Centro",
            "pais":"Mexico",
            "email": "ventas@climasmonterrey.com",
            "RFC":"SCC9612133A1",
            "folio_fiscal": "4f6e1f1d-11b4-4b2b-bc43-8a74d1c54dbd",
            "detalle_del_articulo": 
            [{
                "name":"Colombia Objeto Prueba",
                "cantidad":"1.00",
                "precio": "1.00",
                "importe": "1.00",

            }]
        },
        {
            "id":"2",
            "fecha_factura": "2022-02-09",
            "numero_factura": "A-000947",
            "numero_orden": "",
            "nombre_cliente": "Juan Carlos Sánchez Duque",
            "estado": "BORRADOR",
            "fecha_vencimiento": "2022-05-12",
            "cantidad": "MXN$1.16",
            "saldo_adeudado": "MXN$1.16",
            "sucursal": "Headquarters",
            "precio": "1.00",
            "sub_total": "MXN$1.00",
            "total": "MXN$1.00",
            "cantidad": "MXN$1.00",
            "terminos": "Pagadero a la recepción",
            "vendedor": "Pablo Ignacio Salcedo",
            "forma_pago": "01  Efectivo",
            "USO_CFDI": "G03 Gasto en General",
            "metodo_de_pago": "PPD PAGO PARRCIALIDADES",
            "exportacion": "01 No aplica",
            "medio_de_pago": "C0_ZZZ - Acuerdo mutuo",
            "modo_de_pago":"01 Efectivo",
            "detalle_del_articulo": "Colombia Objeto Prueba",
            "localidad": "Monterrey Nuevo León 64620",
            "provincia":"Monterrey",
            "direccion": "Colon 1014Col. Centro",
            "pais":"Mexico",
            "RFC":"SCC9612133A1",
            "folio_fiscal": "2d4e61e7-edff-4917-940e-c2a19e6f6e84",
            "detalle_del_articulo": 
            [{
                "name":"Colombia Objeto Prueba",
                "cantidad":"1.16",
                "precio": "1.16",
                "importe": "1.16",
            }]
        },
        
        {
        
            "id":"3",
            "fecha_factura": "2022-06-07",
            "numero_factura": "A-000977",
            "numero_orden": "",
            "nombre_cliente": "InfoPortal SA",
            "estado": "VENCIDO",
            "fecha_vencimiento": "2022-06-07",
            "cantidad": "MXN$1.03",
            "saldo_adeudado": "MXN$1.03",
            "sucursal": "Headquarters",
            "precio": "1.03",
            "sub_total": "MXN$1.03",
            "total": "MXN$1.03",
            "cantidad": "MXN$1.03",
            "terminos": "Pagadero a la recepción",
            "vendedor": "Jose Ignacio Salcedo",
            "forma_pago": "01  Efectivo",
            "USO_CFDI": "G03 Gasto en General",
            "metodo_de_pago": "PPD PAGO PARRCIALIDADES",
            "exportacion": "01 No aplica",
            "medio_de_pago": "C0_ZZZ - Acuerdo mutuo",
            "modo_de_pago":"contado",
            "detalle_del_articulo": "Colombia Objeto Prueba",
            "localidad": "Monterrey Nuevo León 64620",
            "provincia":"Monterrey",
            "direccion": "Colon 1014Col. Centro",
            "pais":"Mexico",
            "email": "mramirez@infoportal-mx.com",
            "RFC":"SCC9612133A1",
            "potencial":"Detector de Fugas de Sistema de Alarma INGPRO",
            "detalle_del_articulo": [{
                "name":"Colombia Objeto Prueba",
                "cantidad":"1.03",
                "precio": "1.03",
                "importe": "1.03",
            }]
        },
        {
            "id":"4",
            "fecha_factura": "2022-06-15",
            "numero_factura": "A-000987",
            "numero_orden": "",
            "nombre_cliente": "InfoPortal SA",
            "estado": "PAGADO",
            "fecha_vencimiento": "2022-06-15",
            "cantidad": "MXN$1.15",
            "saldo_adeudado": "MXN$1.15",
            "sucursal": "Headquarters",
            "precio": "1.00",
            "sub_total": "MXN$1.15",
            "total": "MXN$1.15",
            "cantidad": "MXN$1.15",
            "terminos": "Pagadero a la recepción",
            "vendedor": "Jose Ignacio Salcedo",
            "forma_pago": "01  Efectivo",
            "USO_CFDI": "G03 Gasto en General",
            "metodo_de_pago": "PPD PAGO PARRCIALIDADES",
            "exportacion": "01 No aplica",
            "medio_de_pago": "C0_ZZZ - Acuerdo mutuo",
            "modo_de_pago":"99 Por definir",
            "detalle_del_articulo": "Colombia Objeto Prueba",
            "localidad": "Monterrey Nuevo León 64620",
            "provincia":"Monterrey",
            "direccion": "Colon 1014Col. Centro",
            "pais":"Mexico",
            "email": "jcsanchez@optimi.mx",
            "RFC":"SCC9612133A1",
            "potencial":"Test con Max",
            "folio_fiscal": "5c4z62q7-abcc-5717-140e-c6a19e6f6e84",
            "detalle_del_articulo": 
            [{
                "name":"Colombia Objeto Prueba",
                "cantidad":"1.15",
                "precio": "1.15",
                "importe": "1.15",
            }]
        },
        {
            "id":"5",
            "fecha_factura": "2022-09-12",
            "numero_factura": "A-TestCancelar",
            "numero_orden": "",
            "nombre_cliente": "Servi Climas y Calefacciones Monterrey",
            "estado": "ANULADO",
            "fecha_vencimiento": "2022-09-12",
            "cantidad": "MXN$1.15",
            "saldo_adeudado": "MXN$1.15",
            "sucursal": "Headquarters",
            "precio": "1.15",
            "sub_total": "MXN$1.15",
            "total": "MXN$1.15",
            "cantidad": "MXN$1.15",
            "terminos": "Pagadero a la recepción",
            "vendedor": "Jose Ignacio Salcedo",
            "forma_pago": "01  Efectivo",
            "USO_CFDI": "G03 Gasto en General",
            "metodo_de_pago": "PPD PAGO PARRCIALIDADES",
            "exportacion": "01 No aplica",
            "medio_de_pago": "C0_ZZZ - Acuerdo mutuo",
            "modo_de_pago":"contado",
            "detalle_del_articulo": "Colombia Objeto Prueba",
            "localidad": "Monterrey Nuevo León 64620",
            "provincia":"Monterrey",
            "direccion": "Colon 1014Col. Centro",
            "pais":"Mexico",
            "email": "mramirez@infoportal-mx.com",
            "RFC":"SCC9612133A1",
            "potencial":"Test con Max",
            "detalle_del_articulo": 
            [{
                "name":"Colombia Objeto Prueba",
                "cantidad":"1.15",
                "precio": "1.15",
                "importe": "1.15",
            }]
        },
        {
            "id":"6",
            "fecha_factura": "2022-10-24",
            "numero_factura": "A-001164",
            "numero_orden": "",
            "nombre_cliente": "Servi Climas y Calefacciones Monterrey",
            "estado": "PAGADO",
            "fecha_vencimiento": "2022-10-24",
            "cantidad": "MXN$1.05",
            "saldo_adeudado": "MXN$0.00",
            "sucursal": "Headquarters",
            "precio": "1.05",
            "sub_total": "MXN$1.05",
            "total": "MXN$1.05",
            "cantidad": "MXN$1.05",
            "terminos": "Pagadero a la recepción",
            "vendedor": "Jose Ignacio Salcedo",
            "forma_pago": "01  Efectivo",
            "USO_CFDI": "G03 Gasto en General",
            "metodo_de_pago": "PPD PAGO PARRCIALIDADES",
            "exportacion": "01 No aplica",
            "medio_de_pago": "C0_ZZZ - Acuerdo mutuo",
            "modo_de_pago":"contado",
            "detalle_del_articulo": "Colombia Objeto Prueba",
            "localidad": "Monterrey Nuevo León 64620",
            "provincia":"Monterrey",
            "direccion": "Colon 1014Col. Centro",
            "pais":"Mexico",
            "email": "ventas@climasmonterrey.com",
            "RFC":"SCC9612133A1",
            "potencial":"Detector de Fugas de Sistema de Alarma INGPRO",
            "folio_fiscal": "c834b3b1-2dd5-4956-8bca-db1217d10336",
            "detalle_del_articulo": 
            [{
                "name":"Colombia Objeto Prueba",
                "cantidad":"1.05",
                "precio": "1.05",
                "importe": "1.05",
            }]
        },
        {
            "id":"7",
            "fecha_factura": "2022-11-25",
            "numero_factura": "A-001210",
            "numero_orden": "",
            "nombre_cliente": "Servi Climas y Calefacciones Monterrey",
            "estado": "ANULADO",
            "fecha_vencimiento": "2022-11-25",
            "cantidad": "MXN$1.16",
            "saldo_adeudado": "MXN$1.16",
            "sucursal": "Headquarters",
            "precio": "1.16",
            "sub_total": "MXN$1.16",
            "total": "MXN$1.16",
            "cantidad": "MXN$1.16",
            "terminos": "Pagadero a la recepción",
            "vendedor": "Jose Ignacio Salcedo",
            "forma_pago": "01  Efectivo",
            "USO_CFDI": "G03 Gasto en General",
            "metodo_de_pago": "PPD PAGO PARRCIALIDADES",
            "exportacion": "01 No aplica",
            "medio_de_pago": "C0_ZZZ - Acuerdo mutuo",
            "modo_de_pago":"99 Por definir",
            "detalle_del_articulo": "Colombia Objeto Prueba",
            "localidad": "Monterrey Nuevo León 64620",
            "provincia":"Monterrey",
            "direccion": "Colon 1014Col. Centro",
            "pais":"Mexico",
            "email": "ventas@climasmonterrey.com",
            "RFC":"SCC9612133A1",
            "potencial":"Detector de Fugas de Sistema de Alarma INGPRO",
            "folio_fiscal": "2d4e61e7-edff-4917-940e-c2a19e6f6e84",
            "detalle_del_articulo": 
            [{
                "name":"Colombia Objeto Prueba",
                "cantidad":"1.16",
                "precio": "1.16",
                "importe": "1.16",
            }]
        },
        {
            "id":"8",
            "fecha_factura": "2023-08-02",
            "numero_factura": "A-001614",
            "numero_orden": "",
            "nombre_cliente": "Servi Climas y Calefacciones Monterrey",
            "estado": "ANULADO",
            "fecha_vencimiento": "2023-08-02",
            "cantidad": "MXN$1.06",
            "saldo_adeudado": "MXN$1.06",
            "sucursal": "Headquarters",
            "precio": "1.06",
            "sub_total": "MXN$1.06",
            "total": "MXN$1.06",
            "cantidad": "MXN$1.06",
            "terminos": "Pagadero a la recepción",
            "vendedor": "Jose Ignacio Salcedo",
            "forma_pago": "01  Efectivo",
            "USO_CFDI": "G03 Gasto en General",
            "metodo_de_pago": "PPD PAGO PARRCIALIDADES",
            "exportacion": "01 No aplica",
            "medio_de_pago": "C0_ZZZ - Acuerdo mutuo",
            "modo_de_pago":"contado",
            "detalle_del_articulo": "Colombia Objeto Prueba",
            "localidad": "Monterrey Nuevo León 64620",
            "provincia":"Monterrey",
            "direccion": "Colon 1014Col. Centro",
            "pais":"Mexico",
            "email": "",
            "RFC":"SCC9612133A1",
            "potencial":"Detector de Fugas de Sistema de Alarma INGPRO",
            "folio_fiscal": "2d4e61e7-edff-4917-940e-c2a19e6f6e84",
            "detalle_del_articulo": 
            [{
                "name":"Colombia Objeto Prueba",
                "cantidad":"1.06",
                "precio": "1.06",
                "importe": "1.06",
            }]
        },
        {
            "id":"9",
            "fecha_factura": "2023-08-02",
            "numero_factura": "A-001617",
            "numero_orden": "A-001614",
            "nombre_cliente": "Servi Climas y Calefacciones Monterrey",
            "estado": "PAGADO",
            "fecha_vencimiento": "2023-08-02",
            "cantidad": "MXN$1.13",
            "saldo_adeudado": "MXN$0.00",
            "sucursal": "Headquarters",
            "precio": "1.13",
            "sub_total": "MXN$1.13",
            "total": "MXN$1.13",
            "cantidad": "MXN$1.13",
            "terminos": "Pagadero a la recepción",
            "vendedor": "Jose Ignacio Salcedo",
            "forma_pago": "01  Efectivo",
            "USO_CFDI": "G03 Gasto en General",
            "metodo_de_pago": "PPD PAGO PARRCIALIDADES",
            "exportacion": "01 No aplica",
            "medio_de_pago": "C0_ZZZ - Acuerdo mutuo",
            "modo_de_pago":"contado",
            "detalle_del_articulo": "Colombia Objeto Prueba",
            "localidad": "Monterrey Nuevo León 64620",
            "provincia":"Monterrey",
            "direccion": "Colon 1014Col. Centro",
            "pais":"Mexico",
            "email": "mramirez@infoportal-mx.com",
            "RFC":"SCC9612133A1",
            "detalle_del_articulo": 
            [{
                "name":"Colombia Objeto Prueba",
                "cantidad":"1.13",
                "precio": "1.13",
                "importe": "1.13",
            }]
        },
        {
            "id":"10",
            "fecha_factura": "2023-08-02",
            "numero_factura": "A-001616",
            "numero_orden": "A-001614",
            "nombre_cliente": "Servi Climas y Calefacciones Monterrey",
            "estado": "PAGADO",
            "fecha_vencimiento": "2023-08-02",
            "cantidad": "MXN$1.06",
            "saldo_adeudado": "MXN$0.00",
            "sucursal": "Headquarters",
            "precio": "1.06",
            "sub_total": "MXN$1.06",
            "total": "MXN$1.06",
            "cantidad": "MXN$1.06",
            "terminos": "Pagadero a la recepción",
            "vendedor": "Jose Ignacio Salcedo",
            "forma_pago": "01  Efectivo",
            "USO_CFDI": "G03 Gasto en General",
            "metodo_de_pago": "PPD PAGO PARRCIALIDADES",
            "exportacion": "01 No aplica",
            "medio_de_pago": "C0_ZZZ - Acuerdo mutuo",
            "modo_de_pago":"01  Efectivo",
            "detalle_del_articulo": "Colombia Objeto Prueba",
            "localidad": "Monterrey Nuevo León 64620",
            "provincia":"Monterrey",
            "direccion": "Colon 1014Col. Centro",
            "pais":"Mexico",
            "email": "",
            "RFC":"SCC9612133A1",
            "potencial":"Test con Max",
            "detalle_del_articulo": 
            [{
                "name":"Colombia Objeto Prueba",
                "cantidad":"1.06",
                "precio": "1.06",
                "importe": "1.06",
            }]
        },
        {
            "id":"11",
            "fecha_factura": "2023-08-03",
            "numero_factura": "A-001624",
            "numero_orden": "A-001622",
            "nombre_cliente": "EDGAR AYALA",
            "estado": "ANULADO",
            "fecha_vencimiento": "2023-08-03",
            "cantidad": "MXN$1.06",
            "saldo_adeudado": "MXN$1.16",
            "sucursal": "Headquarters",
            "precio": "1.06",
            "sub_total": "MXN$1.06",
            "total": "MXN$1.06",
            "cantidad": "MXN$1.06",
            "terminos": "Pagadero a la recepción",
            "vendedor": "Jose Ignacio Salcedo",
            "forma_pago": "01  Efectivo",
            "USO_CFDI": "G03 Gasto en General",
            "metodo_de_pago": "PPD PAGO PARRCIALIDADES",
            "exportacion": "01 No aplica",
            "medio_de_pago": "C0_ZZZ - Acuerdo mutuo",
            "modo_de_pago":"contado",
            "detalle_del_articulo": "Colombia Objeto Prueba",
            "localidad": "Monterrey Nuevo León 64620",
            "provincia":"Monterrey",
            "direccion": "Colon 1014Col. Centro",
            "pais":"Mexico",
            "email": "ventas@climasmonterrey.com",
            "RFC":"SCC9612133A1",
            "potencial":"Detector de Fugas de Sistema de Alarma INGPRO",
            "folio_fiscal": "2d4e61e7-edff-4917-940e-c2a19e6f6e84",
            "detalle_del_articulo": 
            [{
                "name":"Colombia Objeto Prueba",
                "cantidad":"1.06",
                "precio": "1.06",
                "importe": "1.06",
            }]
        },
       

    ]

}


