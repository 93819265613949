import React, { useContext, useEffect,  useState } from 'react';
import '../styles/facturas.css';
import { IoAdd } from "react-icons/io5";
import { CiSearch } from "react-icons/ci";
import { BsFillArrowDownLeftCircleFill } from "react-icons/bs";
import { facturasData } from '../json/factutas';
import { SelectLimit, Pagination } from '../components';
import { useNavigate } from 'react-router-dom';
import { DataContextPaginacion } from '../contex/DataContexPaginacion';

export const Facturas = () => {
    const [data, setData] = useState([]);
    const navigate = useNavigate();
    const { page, setPage, limit, setLimit, handlePageChange } = useContext(DataContextPaginacion);

    const getElement = (page, limit) => {
        let totalElements = facturasData.facturas.length;
        let adjustedLimit = Math.min(limit, totalElements);
        let start = (page - 1) * adjustedLimit;
        let end = start + adjustedLimit;
        let array = facturasData.facturas.slice(start, end);
        setData(array);
    };

    const getLength = () => facturasData.facturas.length;
    let totalPage = Math.ceil(getLength() / limit);

    useEffect(() => {
        getElement(page, limit);
    }, [page, limit]);

    const handleRowClick = (item) => {
        navigate(`/facturas/${item.id}`, { 
            state: { 
                factura: item, 
                data: facturasData.facturas, 
                page, 
                limit 
            } 
        });
    };

    return (
        <div className='facturas_conatiner'>
            <div className='facturas_conatiner_h2'>
                <h2>Todas las facturas</h2>
                <div className='facturas_conatiner_btn'>
                    <button className='facturas_conatiner_btn_nuevo'>
                        <IoAdd size={24}/> Nuevo
                    </button>
                    <button className='facturas_conatiner_btn_opcion'>
                        <span style={{ fontSize: '20px' }}>⋮</span>
                    </button>
                </div>
            </div>

            <div className='facturas_subConatiner'>
                <h4>Resumen de pago</h4>
                <div className='facturas_subConatiner_resumen'>
                    <div className='facturas_subConatiner_resumen-1'>
                        <div className='facturas_subConatiner_resumen-icon'>
                            <BsFillArrowDownLeftCircleFill size={50}/>
                        </div>
                        <div className='facturas_subConatiner_resumen-total'>
                            <p className='normal'>Total de cuentas pendientes de cobro</p>
                            <p className='negrita'>MXN$310,032.34</p>
                        </div>
                    </div>
                    <div className='facturas_subConatiner_resumen-2'>
                        <p className='normal'>Vencidos hoy</p>
                        <p className='vencidos negrita'>MXN$0.00</p>
                    </div>
                    <div className='facturas_subConatiner_resumen-3'>
                        <p className='normal'>Vence en los próximos 30 días</p>
                        <p className='negrita'>MXN$86,336.34</p>
                    </div>
                    <div className='facturas_subConatiner_resumen-4'>
                        <p className='normal'>Facturas vencida</p>
                        <p className='negrita'>MXN$22.696.00</p>
                    </div>
                    <div className='facturas_subConatiner_resumen-5'>
                        <p className='normal'>Cantidad promedio de días para cobrar</p>
                        <p className='negrita'>21 días</p>
                    </div>
                </div>

                <table className='facturas_conatiner_tabla'>
                    <thead className='facturas_conatiner_tabla_title'>
                        <tr>
                            <th>FECHA</th>
                            <th>N° DE FACTURA</th>
                            <th>NÚMERO DE ORDEN</th>
                            <th>NOMBRE DEL CLIENTE</th>
                            <th>ESTADO</th>
                            <th>FECHA DE VENCIMIENTO</th>
                            <th>CANTIDAD</th>
                            <th>SALDO ADEUDADO</th>
                            <th>SUCURSAL</th>
                            <th className="facturas_conatiner_tabla_title_col-2"><CiSearch size={24}/></th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item, index) => (
                            <tr key={index} className='facturas_conatiner_tabla_fila' 
                                onClick={() => handleRowClick(item)}>
                                <td>{item.fecha_factura}</td>
                                <td className='n_factura'>{item.numero_factura}</td>
                                <td>{item.numero_orden}</td>
                                <td>{item.nombre_cliente}</td>
                                <td className={item.estado === 'PAGADO' ? 'estado_pagado' : ''}>{item.estado}</td>
                                <td>{item.fecha_vencimiento}</td>
                                <td>{item.cantidad}</td>
                                <td>{item.saldo_adeudado}</td>
                                <td>{item.sucursal}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <div className='facturas_pagination-container'>
                <div className='facturas_pagination-container-select'>
                    <SelectLimit onLimitChange={setLimit} setPage={setPage} currentLimit={limit}/>
                    <Pagination totalPage={totalPage} page={page} limit={limit} 
                        siblings={1} onPageChange={handlePageChange} menu={false}/>
                </div>
            </div>
        </div>
    );
};
