import React, { useContext, useEffect, useRef, useState } from 'react';
import '../styles/facturacionDetallada.css';
import { IoAdd } from "react-icons/io5";
import { HiLogin } from "react-icons/hi";
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { facturasData } from '../json/factutas';
import { SelectLimit, Pagination, CanvasPdf } from '../components';
import { DataContextPaginacion } from '../contex/DataContexPaginacion';
import { Facturas } from '../page/Facturas';


export const FacturacionDetallada = () => {

    const [pdfContainerHeight, setPdfContainerHeight] = useState(0); // Estado para la altura

    const navigate = useNavigate();
    const { id } = useParams(); 
    const location = useLocation(); 
    const factura = location.state?.factura; 
    const dato = location.state?.data; 
    const [showMenu, setShowMenu] = useState(true);

    


    // Estado para el elemento seleccionado
    const [selectedItemId, setSelectedItemId] = useState(location.state?.factura.id);
    
    const handleItemClick = (item) => {
        
        setSelectedItemId(item.id);
        navigate(`/facturas/${item.id}`, { 
            state: { 
                factura: item, 
                data: dato, 
                page, 
                limit 
            } 
        });
    };

    /* ------------------- paginacion ----------------------------------*/

    const { page, setPage, limit, setLimit, handlePageChange } = useContext (DataContextPaginacion);
    const [data, setData] = useState([]);

    const getElement = (page, limit) => {
        let totalElements = dato.length;
        let adjustedLimit = Math.min(limit, totalElements); // Asegura que el límite no exceda el total de elementos
        let start = (page - 1) * adjustedLimit;
        let end = start + adjustedLimit;

        let array = dato.slice(start, end);
        setData(array);
    };

    const getLength = () => {
        return dato.length;
    };

    let totalPage = Math.ceil(getLength() / limit);

    useEffect(() => {
        getElement(page, limit);
    }, [page, limit]);


/*--------------------------------------------------------------------- */
    // Función callback para obtener la altura desde CanvasPdf
    const handleHeightCalculated = (height) => {
        setPdfContainerHeight(height);
    };

    const getColorByState = (estado) => {
        switch (estado) {
            case 'ANULADO':
                return '#777';
            case 'PAGADO':
                return '#22b378';
            case 'BORRADOR':
                return '#879697';
            case 'VENCIDO':
                return '#f76831';
            default:
                return 'black'; 
        }
    };

    useEffect(() => {
        
    }, [factura]);

    /* Descarga de PDF*/

    const handleDownloadPdf = () => {
        const imgElement = document.querySelector('.facturacionDetallada_subConatiner_PDF img');
        if (imgElement && imgElement.src) {
            const link = document.createElement('a');
            link.href = imgElement.src;
            link.download = `Factura_${factura.numero_factura}.pdf`;
            link.click();
        } else {
            console.error("No se pudo encontrar la imagen del PDF o la imagen no tiene una fuente.");
        }
    };

    return (
        <div className='facturacionDetallada'>
            <div className={`${showMenu ? 'facturacionDetallada-container' : 'facturacionDetallada-container_no'}`}>
                <HiLogin size={24} onClick={()=>{navigate('/facturas')}}/>
                <button className='facturacionDetallada_subConatiner_btn_cerrar'
                    onClick={() => setShowMenu(!showMenu)}>
                    <span className={`${showMenu ? 'cerrar' : 'button'}`} >{showMenu ? ' <- Menu ' : 'Menu ->'}</span>
                </button>
            </div>
            
            <div className='facturacionDetallada_container'>
                <div className={`facturacionDetallada_subConatiner_izq ${showMenu ? '' : 'none'}`}>
                    <div  className='facturacionDetallada_subConatiner_izq-tile-info'>
                        <div className='facturacionDetallada_subConatiner_izq-title'>
                            <h2>Todas las facturas</h2>
                            <div className='facturacionDetallada_subConatiner_btn'>
                                <button className='facturacionDetallada_subConatiner_btn_nuevo'>
                                    <IoAdd size={24}/> Nuevo
                                </button>
                                <button className='facturacionDetallada_subConatiner_btn_opcion'>
                                    <span style={{ fontSize: '20px' }}>⋮</span>
                                </button>
                            </div>
                        </div>

                        <div className='facturacionDetallada_subConatiner_lista'>
                            {data.map((item, index) => (
                                <div 
                                    key={index} 
                                    className={`facturacionDetallada_subConatiner_lista-map ${(item.id === selectedItemId) && (location.state?.factura.id === selectedItemId) ? 'selected' : ''}`} 
                                    onClick={() => handleItemClick(item)}
                                >
                                    <div className='facturacionDetallada_subConatiner_lista-map-1'>
                                        <p className='map-1-cliente'>{item.nombre_cliente}</p>
                                        <p>{item.cantidad}</p>
                                    </div>
                                    <div className='facturacionDetallada_subConatiner_lista-map-2'>
                                        <p>{item.numero_factura}</p>
                                        <p>.</p>
                                        <p>{item.fecha_factura}</p>
                                        <p>.</p>
                                        <p>{item.sucursal}</p>
                                    </div>
                                    <div className='facturacionDetallada_subConatiner_lista-map-3' 
                                        style={{ color: getColorByState(item.estado) }}>
                                        <p>{item.estado}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className='facturacionDetallada_pagination-container'>
                        <div className='facturacionDetallada_pagination-container-select'>
                            <SelectLimit onLimitChange={setLimit} setPage={setPage} currentLimit={limit}/>
                            <Pagination totalPage={totalPage} page={page} limit={limit} 
                                siblings={1} onPageChange={handlePageChange} menu={true}/>
                        </div>
                    </div>
                </div>

                <div className={`${showMenu ? 'facturacionDetallada_subConatiner_der' : 'facturacionDetallada_subConatiner_der-sinMenu'}`}>
                    <div className='facturacionDetallada_subConatiner_der_header'>
                        <span className='span'>:&nbsp;  SucursalHeadquarters</span>
                        <h2>{factura.numero_factura}</h2>

                        
                            <table className='facturas_subConatiner_tabla'>
                                <thead className='facturas_conatiner_tabla_title'>
                                    <tr>
                                        <th>Editar</th>
                                        <th>Enviar correo</th>
                                        <th>Compartir</th>
                                        <th>Recordatorios</th>
                                        <th  onClick={handleDownloadPdf} >PDF/Imprimir</th>
                                        <th>Registrar pago</th>
                                        <th>Emitir DIAN</th>
                                        <th>⋮</th>
                                    </tr>
                                </thead>
                            </table>
                    </div>  

                    <div className='facturacionDetallada_scrollable'>
                        <div className='facturacionDetallada_subConatiner_title-registre'>
                            <h3>WHAT'S NEXT?</h3>
                            <p>Se ha enviado la factura. Registre su pago tan pronto como lo reciba.</p>
                            <span>Más información</span>
                            <button>Registra pago</button>
                        </div>

                        <div className='facturacionDetallada_subConatiner_title-conf'>
                            <p>Reciba pago con más rapidez configurando pasarelas de pago en línea.</p>
                            <span>Configura ahora</span>
                        </div>

                        <div className='facturacionDetallada_subConatiner_title-orden'>
                            <p>Órdenes de ventas asociadas</p>
                        </div>

                        <div className='facturacionDetallada_subConatiner_estado' >
                                {factura && (
                                    <div className='facturacionDetallada_subConatiner_estado-p' 
                                        style={{ backgroundColor: getColorByState(factura.estado), 
                                        padding: "2px 0px", borderRadius: '12px 12px 0px 0px' }}>            
                                        <p>{factura.estado}</p>
                                    </div> 
                                )}
                            </div>

                        <div   className='facturacionDetallada_subConatiner_PDF' style={{ height: `${pdfContainerHeight}px` }}>
                            <CanvasPdf onHeightCalculated={handleHeightCalculated} factura={factura}/>
                        </div> 

                        <div className='facturacionDetallada_subConatiner_info'>
                            <h5>Más información</h5>
                        </div>

                        {factura.vendedor && (
                            <div className='facturacionDetallada_subConatiner_info-info'>
                                <label>Vendedor</label>
                                <p>: {factura.vendedor}</p>
                            </div>
                        )}

                        {factura.potencial && (
                            <div className='facturacionDetallada_subConatiner_info-info'>
                                <label>Potencial</label>
                                <p>: {factura.potencial}</p>
                            </div>
                        )}

                        {factura.email && (
                            <div className='facturacionDetallada_subConatiner_info-info'>
                                <label>Correos electrónicos <br /> seleccionados <AiOutlineExclamationCircle /></label>
                                <p>: {factura.email}</p>
                            </div>
                        )}

                        <div className='facturacionDetallada_subConatiner_info'>
                            <h4>CAMPOS PERSONALIZADOS</h4>
                        </div>

                        {factura.metodo_de_pago && (
                            <div className='facturacionDetallada_subConatiner_info-info'>
                                <label>Método de Pago</label>
                                <p>: {factura.metodo_de_pago}</p>
                            </div>
                        )}
                        {factura.modo_de_pago && (
                            <div className='facturacionDetallada_subConatiner_info-info'>
                                <label>Modo de Pago</label>
                                <p>: {factura.modo_de_pago}</p>
                            </div>
                        )}
                        {factura.folio_fiscal && (
                            <div className='facturacionDetallada_subConatiner_info-info'>
                                <label>Folio Fiscal</label>
                                <p>: {factura.folio_fiscal}</p>
                            </div>
                        )}
                        <div className='facturacionDetallada_subConatiner_info-info'>
                            <label>URL de PDF</label>
                            <p>:  https://facturacionzoho.com/factdigital/API/PDF?UUID=2d4e61e7-edff-4917-940e-c2a19e6f6e84</p>
                        </div>

                        <div className='facturacionDetallada_subConatiner_info-info'>
                            <label>URL de XML</label>
                            <p>:  https://facturacionzoho.com/factdigital/API/PDF?UUID=2d4e61e7-edff-4917-940e-c2a19e6f6e84/sdsfds</p>
                        </div>

                        {factura.fecha_factura && (
                            <div className='facturacionDetallada_subConatiner_info-info'>
                                <label>Fecha de Emisión</label>
                                <p>: {factura.fecha_factura}</p>
                            </div>
                        )}

                        {factura.numero_factura && (
                            <div className='facturacionDetallada_subConatiner_info-info'>
                                <label>Número de Factura</label>
                                <p>: {factura.numero_factura}</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
