import React, { useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import "../styles/register.css"

export const Register = () => {

  const [formData, setFormData] = useState({
    OrganizationID: '',
    Name: '',
    Email: '',
    Password: '',
    ConfirmPassword: ''
  });

  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const history = useNavigate();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.Password !== formData.ConfirmPassword) {
      setErrorMessage('Las contraseñas no coinciden');
      return;
    }

    setIsLoading(true);
    try {

      const response = await axios.post('https://facturacionei3.com/ep_UserEcart/Signup', {
        Name: formData.Name,
        Email: formData.Email,
        OrganizationID: formData.OrganizationID,
        Password: formData.Password,
        Status: 0,
      });

      console.log("response:", response);

      if (response.status === 500) {
        setErrorMessage('El OrganizationID ya existen');
      } else {
        if (response.status === 200) {
          history('/');
        } else {
          setErrorMessage('Ocurrió un error en el registro. Intente nuevamente');
        }
      }
    } catch (error) {
      console.error('Error:', error);
      setErrorMessage('Ocurrió un error. Por favor, intente nuevamente');
    }
  };

  return (
    <div className="register_container">
      <div className="register_subContainer">
        <h2>Regístrate</h2>
        <form className="register_form" onSubmit={handleSubmit}>
          <div className="mb-1 wide">
            <label htmlFor="OrganizationID">OrganizationID</label>
            <input 
              type="text" 
              className="form-control" 
              id="OrganizationID" 
              name="OrganizationID" 
              value={formData.OrganizationID} 
              onChange={handleChange} 
              required 

            />
          </div>
          <div className="mb-1 wide">
            <label htmlFor="Name">Nombre o Empresa</label>
            <input 
              type="text" 
              className="form-control" 
              id="Name" 
              name="Name" 
              value={formData.Name} 
              onChange={handleChange} 
              required 

            />
          </div>
          <div className="mb-1 wide">
            <label htmlFor="Email">Email</label>
            <input 
              type="email" 
              className="form-control" 
              id="Email" 
              name="Email" 
              value={formData.Email} 
              onChange={handleChange} 
              required 

            />
          </div>
          <div className="mb-3">
            <label htmlFor="Password">Contraseña</label>
            <input 
              type="password" 
              className="form-control" 
              id="Password" 
              name="Password" 
              value={formData.Password} 
              onChange={handleChange} 
              required 

            />
          </div>
          <div className="mb-3">
            <label htmlFor="ConfirmPassword" >Confirmar Contraseña</label>
            <input 
              type="password" 
              className="form-control" 
              id="ConfirmPassword" 
              name="ConfirmPassword" 
              value={formData.ConfirmPassword} 
              onChange={handleChange} 
              required 
            />
          </div>

          <button type="submit" className="btn btn-primary btn-lg mt-3" disabled={isLoading}>
            {isLoading ? <span className="password-recovery-spinner"></span> : 'Registrarse'}
          </button>
          
          {errorMessage && <div className="alert alert-danger mt-3">{errorMessage}</div>}
        </form>

        <p className="register_login mt-3">
          Ya tengo cuenta <Link to="/">Login</Link>
        </p>
      </div>
      
    </div>
  );
}
