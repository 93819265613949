import React from 'react';
import '../styles/spinnerModal.css'

export const SpinnerModal = () => {
    return (
        
        <div className='spinnerModal_container'> 
        
            <div className='spinner'></div>

        </div>

    )
}


