import React, { useEffect } from 'react';
import '../styles/calendar.css';
import dayjs from 'dayjs';
import 'dayjs/locale/es';  
import localeData from 'dayjs/plugin/localeData';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';

dayjs.extend(localeData);
dayjs.locale('es');  // idioma en español

export const Calendar = ({ setConsumptionDate }) => {
    const currentYear = dayjs().year();
    const minDate = dayjs().year(currentYear - 3).startOf('year');
    const maxDate = dayjs().year(currentYear + 3).endOf('year');

  // Maneja el cambio de fecha
    const handleDateChange = (date) => {
        if (date) {
        // Establece siempre el primer día del mes seleccionado
            const firstDayOfMonth = date.startOf('month');
            const alwaysFirstDay = firstDayOfMonth.format('YYYY-MM-01');
            console.log('Fecha seleccionada (primer día del mes):', alwaysFirstDay);
            setConsumptionDate(alwaysFirstDay);
        }
    };

  // Establece el primer día del mes actual al iniciar
    useEffect(() => {
        const today = dayjs();
        const firstDayOfCurrentMonth = today.startOf('month').format('YYYY-MM-01');
        setConsumptionDate(firstDayOfCurrentMonth);
    }, [setConsumptionDate]);

    return (
        <div className='calendar_container'>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DateCalendar']}>
            <DemoItem>
                <DateCalendar
                defaultValue={dayjs()}
                views={['year', 'month']}
                minDate={minDate}
                maxDate={maxDate}
                onChange={handleDateChange}
                />
            </DemoItem>
            </DemoContainer>
        </LocalizationProvider>
        </div>
    );
};
