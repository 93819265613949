import React from 'react';
import { returnPaginationRange,returnPaginationRangeMenu } from '../utils/appUtils';


export const Pagination = ({ totalPage, page, limit, siblings, onPageChange, menu }) => {
    
    const array = menu === true ?  returnPaginationRangeMenu(totalPage, page) :  returnPaginationRange(totalPage, page);

    return (
        <ul className="pagination pagination-md justify-content-end">
            <li className="page-item">
                <span
                    onClick={() => onPageChange(1)}
                    className="page-link"
                    style={{ color: 'var(--primary-color)', fontWeight: '700', cursor: 'pointer' }}
                >
                    &laquo;
                </span>
            </li>
            <li className="page-item">
                <span
                    onClick={() => onPageChange(page > 1 ? page - 1 : 1)}
                    className="page-link"
                    style={{ color: 'var(--primary-color)', fontWeight: '700', cursor: 'pointer' }}
                >
                    &lsaquo;
                </span>
            </li>
            {
                array.map((value, index) => (
                    value === page ? (
                        <li key={index} className='page-item active'>
                            <span
                                className="page-link"
                                style={{
                                    color: '#fff',
                                    backgroundColor: 'var(--primary-color)',
                                    borderColor: 'var(--primary-color)'
                                }}
                            >
                                {value}
                            </span>
                        </li>
                    ) : (
                        <li key={index} className='page-item'>
                            <span
                                onClick={() => value !== "..." && onPageChange(value)}
                                className="page-link"
                                style={{ color: 'var(--primary-color)', fontWeight: '700', cursor: value === "..." ? 'default' : 'pointer' }}
                            >
                                {value}
                            </span>
                        </li>
                    )
                ))
            }
            <li className="page-item">
                <span
                    onClick={() => onPageChange(page < totalPage ? page + 1 : totalPage)}
                    className="page-link"
                    style={{ color: 'var(--primary-color)', fontWeight: '700', cursor: 'pointer' }}
                >
                    &rsaquo;
                </span>
            </li>
            <li className="page-item">
                <span
                    onClick={() => onPageChange(totalPage)}
                    className="page-link"
                    style={{ color: 'var(--primary-color)', fontWeight: '700', cursor: 'pointer' }}
                >
                    &raquo;
                </span>
            </li>
        </ul>
    );
}
