import React, {useState} from 'react';
import axios from 'axios';
import { CiCircleQuestion } from "react-icons/ci";
import { FaArrowsRotate } from "react-icons/fa6";
import '../styles/newArticle.css';

export const NewArticle = () => {
    const [formData, setFormData] = useState({
        image: null
    });

    const [imagePreview, setImagePreview] = useState(null);

    const handleInputChange = (e) => {
        const { name, value, type, checked, files } = e.target;
        if (type === 'file') {
            setFormData({
                ...formData,
                image: files[0]
            });
            // Generate a preview URL for the image
            setImagePreview(URL.createObjectURL(files[0]));
        } else {
            setFormData({
                ...formData,
                [name]: type === 'checkbox' ? checked : value
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = new FormData();
        Object.keys(formData).forEach(key => {
            if (key === 'image' && formData.image) {
                data.append('image', formData.image);
            } else {
                data.append(key, formData[key]);
            }
        });

        try {
            const res = await axios.post('/api/items', data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            
        } catch (error) {
            console.log('Error:', error);
        }
    };

    return (
        <form className="new-item-form" onSubmit={handleSubmit}>
            <h2>Nuevo artículo</h2>

            <div className='newArticle_subcontainer'>
                <div className="form-left">

                    <div className='form-left-grid'>
                        <label>Tipo <CiCircleQuestion /></label>
                        <div>
                            <input type="radio" name="type" value="goods"  />
                            <label>Bienes</label>
                            <input type="radio" name="type" value="service"  />
                            <label>Servicio</label>
                        </div>
                        
                    </div>

                    <div className='form-left-grid'>
                        <label>Nombre* </label>
                        <input type="text" value='' />
                        
                    </div>

                    <div className='form-left-grid'>
                        <label>SKU (Código de artículo)* <CiCircleQuestion /></label>
                        <input type="text" value='' />
                        
                    </div>
                    
                    <div className='form-left-grid'>
                        <label>Unidad*: <CiCircleQuestion /></label>
                        <select name="unit" value='' >
                            <option value="">Seleccione o escriba para agregar</option>
                            {/* Añade opciones aquí */}
                        </select>
                    </div>

                    <div className='form-left-grid'>
                        <label>Categoría:</label>
                        <select name="category" value=''>
                            <option value="">Seleccionar una categoría</option>
                            {/* Añade opciones aquí */}
                        </select>
                    </div>

                    <div className='form-left-grid'>
                        <div></div>
                        <div>
                            <input type="checkbox" />
                            <label>Artículo retornable</label>
                        </div>   
                    </div>
                </div>

                <div className="form-right">
                    <label className="image-upload-label">
                        <div className="image-upload-box">
                            <p>Arrastrar imágenes aquí </p>
                            <input type="file" name="image" accept="image/*"  onChange={handleInputChange}/>
                        </div>
                        <span className="image-upload-info">
                            <CiCircleQuestion /> Puede agregar un máximo de 15 imágenes, cada una de las cuales no puede ser mayor que 5 MB.
                        </span>
                        {imagePreview && (
                            <div className="image-preview">
                                <img src={imagePreview} alt="Vista previa" className="preview-image" />
                            </div>
                        )}
                    </label>
                </div>
            </div>

            <div className='newArticle_subcontainer'>
                <div className="form-left">
                    <div className='form-left-grid'>
                        <label>Dimensiones (Longitud X Ancho X Altura)</label>
                        <div className="dimensions">
                            <input type="text" name="dimensions.medida" value=''  />
                            <select name="dimensions.unit" value='' >
                                <option value="cm">cm</option>
                                <option value="m">m</option>
                                {/* Añade más unidades aquí */}
                            </select>
                        </div>
                    </div>

                    <div className='form-left-grid'>
                        <label>Fabricante</label>
                        <select name="manufacturer" value='' >
                            <option value="">Seleccionar o agregar fabricante</option>
                            {/* Añade opciones aquí */}
                        </select>
                    </div>
                    <div className='form-left-grid'>
                        <label>UPC <CiCircleQuestion /></label>
                        <input type="text" name="upc" value=''  />
                    </div>
                    <div className='form-left-grid'>
                        <label>EAN <CiCircleQuestion /></label>
                        <input type="text" name="ean" value=''  />
                    </div>
                    <div className='form-left-grid'>
                        <label>Clave SAT*</label>
                        <input type="text" name="satKey" value=''  />
                    </div>
                    <div className='form-left-grid'>
                        <label>Es un Producto USD:</label>
                        <div>
                            <input className='input' type="checkbox"    />
                        </div>
                    </div>
                    <div className='form-left-grid'>
                        <label>Costo USD</label>
                        <input type="text" name="costUSD" value=''  />
                    </div>
                    <div className='form-left-grid'>
                        <label>Venta USD</label>
                        <input type="text" name="saleUSD" value=''  />
                    </div>
                    <div className='form-left-grid'>
                        <label>Línea en Venta</label>
                        <select name="lineaVenta" value='' >
                            <option value=""></option>
                            {/* Añade opciones aquí */}
                        </select>
                    </div>
                    <div className='form-left-grid'>
                        <label>PX</label>
                        <select name="PX" value='' >
                            <option value=""></option>
                            {/* Añade opciones aquí */}
                        </select>
                    </div>
                    <div className='form-left-grid'>
                        <label>ID Estandár</label>
                        <input type="text" value=''  />
                    </div>
                </div>

                <div className="form-right">

                    <div className='form-right-grid'>
                        <label>Peso</label>
                        <div className="dimensions">
                        <input type="text" value=''  />
                            <select name="weightUnit" value='' >
                            <option value="kg">kg</option>
                            <option value="g">g</option>
                                {/* Añade más unidades aquí */}
                            </select>
                        </div>
                    </div>
                    <div className='form-right-grid'>
                        <label>Marca</label>
                        <select value='' >
                            <option value="">Seleccionar o agregar marca</option>
                            {/* Añade opciones aquí */}
                        </select>
                    </div>
                    <div className='form-right-grid'>
                        <label>MPN <CiCircleQuestion /></label>
                        <input type="text" value=''  />
                    </div>
                    <div className='form-right-grid'>
                        <label>ISBN <CiCircleQuestion /></label>
                        <input type="text"  value='' />
                    </div>
                    <div className='form-right-grid'>
                        <label>Objeto de Impuesto*</label>
                        <select value='' >
                            <option value=''>02 Sí objeto de impuesto.</option>
                            {/* Add more options here */}
                        </select>
                    </div>
                    <div className='form-right-grid'>
                        <label>Costo MXN</label>
                        <input type="text" value='' />
                    </div>
                    <div className='form-right-grid'>
                        <label>Venta MXN</label>
                        <input type="text" value=''  />
                    </div>
                    <div className='form-right-grid'>
                        <label>Es un Producto USD</label>
                        <div>
                            <input className='input' type="checkbox"  checked=''/>
                        </div>
                    </div>
                    <div className='form-right-grid'>
                        <label>APP*</label>
                        <select name="lineaVenta" value='' >
                            <option value=""></option>
                            {/* Añade opciones aquí */}
                        </select>
                    </div>
                    <div className='form-right-grid'>
                        <label>Tipo de ID Estandár <CiCircleQuestion /></label>
                        <select value='' >
                            <option value=''>Estandár de odopción...</option>
                            {/* Add more options here */}
                        </select>
                    </div>
                    <div className='form-right-grid'>
                        <label>Unidad de Medida</label>
                        <select name="lineaVenta" value='' >
                            <option value=""></option>
                            {/* Añade opciones aquí */}
                        </select>
                    </div>
                </div>
            </div>

            <hr />

            <div className='newArticle_subcontainer'>
                <div className="form-left">
                    <div className='form-left-grid_grid'>
                        <input className='input' type="checkbox"    />
                        <label>Información de Ventas</label>
                    </div>
                    <div className='form-left-grid'>
                        <label>Precio de Venta*</label>
                        <input type="text" name="costUSD" value=''  />
                    </div>
                    <div className='form-left-grid'>
                        <label>Cuentas*</label>
                        <select value='' >
                            <option value=''>[400.00.000 Ingresos Totales]</option>
                            {/* Add more options here */}
                        </select>
                    </div>
                    <div className='form-left-grid'>
                        <label>Descripción</label>
                        <textarea name="textarea" rows="5" cols="2"></textarea>
                    </div>
                    <div className='form-right-grid'>
                        <label>Impuesto <CiCircleQuestion /></label>
                        <select value='' >
                            <option value=''>Selecciona una impuesto...</option>
                            {/* Add more options here */}
                        </select>
                    </div>
                </div>

                <div className="form-right">
                    <div className='form-left-grid_grid'>
                        <input className='input' type="checkbox"    />
                        <label>Información de la Compra</label>
                    </div>
                    <div className='form-left-grid'>
                        <label>Precio de Compra*</label>
                        <input type="text" name="costUSD" value=''  />
                    </div>
                    <div className='form-left-grid'>
                        <label>Cuentas*</label>
                        <select value='' >
                            <option value=''>[400.00.000 Ingresos Totales]</option>
                            {/* Add more options here */}
                        </select>
                    </div>
                    <div className='form-left-grid'>
                        <label>Descripción</label>
                        <textarea name="textarea" rows="5" cols="2"></textarea>
                    </div>
                    <div className='form-right-grid'>
                        <label>Impuesto <CiCircleQuestion /></label>
                        <select value='' >
                            <option value=''>Selecciona una impuesto...</option>
                            {/* Add more options here */}
                        </select>
                    </div>
                    <div className='form-right-grid'>
                        <label>Proveedor Preferído</label>
                        <select name="lineaVenta" value='' >
                            <option value=""></option>
                            {/* Añade opciones aquí */}
                        </select>
                    </div>
                </div>
            </div>

            <hr />

            <div className='newArticle_subcontainer'>
                <div className="form-left">
                    <div className='form-left-grid_checkbox'>
                        <div>
                            <input className='input' type="checkbox"    />
                            <label>Seguimiento de inventario de este artículo <CiCircleQuestion /></label>
                        </div>
                        <span className="image-upload-info">
                            <CiCircleQuestion /> 
                            No puede habilitar/deshabilitar el seguimiento de inventario después de haber creado transacciones para este artículo
                        </span>
                    </div>

                    <div className='form-left-grid'>
                        <p>Seguimiento de inventario avanzado</p>
                    </div>
                    <div className='form-left-grid'>
                        <div className='form-left-grid_chebox'>
                            <div>
                                <input type="radio" name="type" value="goods"  />
                                <label>Ninguno</label>
                            </div>
                            <div>
                                <input type="radio" name="type" value="goods"  />
                                <label>Número de serie de seguimiento</label>
                            </div>
                            <div>
                            <input type="radio" name="type" value="service"  />
                            <label>Hacer seguimiento de los lotes</label>
                            </div>
                        </div>
                    </div>
                    <div className='form-left-grid'>
                        <label>Cuentas de Inventario*</label>
                        <select value='' >
                            <option value=''>Seleccione una cuenta</option>
                            {/* Add more options here */}
                        </select>
                    </div>
                    <div className='form-left-grid'>
                        <label>Punto de reposición</label>
                        <input type="text"  value=''  />
                    </div>
                </div>
            </div>

            <hr />

            <div className='newArticle_subcontainer_grid'>
                <div>
                    <p className='secion_almacen'>NOMBRE DEL ALMACÉN</p>
                </div>
                <div>
                    <p className='secion_almacen'>EXISTENCIAS DE APERTURAS</p>
                    <p className='secion_almacen copia'>COPIA EN TODO</p>
                </div>    
                <div>
                    <p className='secion_almacen'>VALOR INICIAL DE EXSTENCIAS <br/> PRO UNIDAD</p><br />
                    <p className='secion_almacen copia'>COPIA EN TODO</p>
                </div>  
            </div>

            <hr />

            <div className='newArticle_subcontainer_grid'>
                <div>
                    <select value='' >
                        <option value=''>0</option>
                        {/* Add more options here */}
                    </select>
                </div>
                <div>
                    <input type="text" name="" id="" />
                </div>    
                <div>
                    <input type="text" name="" id="" />
                </div>  
            </div>

            <hr />

            <div className='newArticle_subcontainer'>
                <div className="form-left">
                    
                    <div className='form-left-grid'>
                        <label>Departamento</label>
                        <select value=''>
                            <option value=''></option>
                            {/* Add more options here */}
                        </select>
                    </div>
                    <div className='form-left-grid'>
                        <label>Territorio</label>
                        <select value='' >
                            <option value=''></option>
                            {/* Add more options here */}
                        </select>
                    </div>
                    
                </div>

                <div className="form-right">
                    <div className='form-right-grid'>
                        <label>Licencias</label>
                        <select  value='' >
                            <option value=''>Seleccione una cuenta</option>
                            {/* Add more options here */}
                        </select>
                    </div>
                    <div className='form-right-grid'>
                        <label>Tipo de ingreso</label>
                        <select value='' >
                            <option value=''>Seleccione una cuenta</option>
                            {/* Add more options here */}
                        </select>
                    </div>
                </div>
            </div>

            <hr />

            <div className='newArticle_footer'>
                <div className='newArticle_footer_btn'>
                    <button className='btn footer_btn'>Guardar como borrador</button>
                    <button className='btn btn-primary'>Guardar y enviar</button>
                    <button className='btn footer_btn'>Cancelar</button>
                </div>

                <div className='newArticle_footer_total'>
                    <p className='newArticle_footer_total_p'> <FaArrowsRotate />Hacer recurrente</p>
                    <div className='newArticle_footer_cantidadTotal'>
                        <p>Cantidad total: MXN$ 0.00</p>
                        <span>Cantidad total: 0</span>
                    </div>
                </div>

            </div>
            
        </form>
    );       
}
