import React, {useContext, useState, useEffect } from 'react';
import { ordenVenta } from '../json/ordenVenta';
import { IoAdd } from "react-icons/io5";
import { CiSearch } from "react-icons/ci";
import '../styles/ordenCompra.css'
import { SelectLimit, Pagination } from '../components';
import { DataContextPaginacion } from '../contex/DataContexPaginacion';
import { useNavigate } from 'react-router-dom';

export const OrdenCompra = () => {

    const [data, setData] = useState([]);
    const navigate = useNavigate();
    const { page, setPage, limit, setLimit, handlePageChange } = useContext(DataContextPaginacion);
    
/* ------------------- paginacion ----------------------------------*/

    const getElement = (page, limit) => {
        let totalElements = ordenVenta.ordenVenta.length;
        let adjustedLimit = Math.min(limit, totalElements); // Asegura que el límite no exceda el total de elementos
        let start = (page - 1) * adjustedLimit;
        let end = start + adjustedLimit;

        let array = ordenVenta.ordenVenta.slice(start, end);
        setData(array);
    };

    const getLength = () => {
        return ordenVenta.ordenVenta.length;
    };

    let totalPage = Math.ceil(getLength() / limit);

    useEffect(() => {
        getElement(page, limit);
    }, [page, limit]);

    const handleRowClick = (item) => {
        navigate(`/orden-compra/${item.id}`, { 
            state: { 
                compra: item, 
                data: ordenVenta.ordenVenta, 
                page, 
                limit 
            } 
        });
    };

 /*--------------------------------------------------------------------- */


    const getEstadoColor = (estado) => {
        switch (estado) {
            case 'CONFIRMADO':
                return { color: '#6da3fc' };
            case 'APROBADO':
                return { color: '#89bad0' };
            case 'APROBACIÓN PENDIENTE':
                return { color: '#ec762d' };
            case 'ANULADO':
                return { color: '#868393' };
            case 'BORRADOR':
                return { color: '#96a3a8' };
            case 'CERRADO':
                return { color: '#45b98f' };
            default:
                return { color: '#000' }; 
        }
    };

    const getEstadoPedidoColor = (estadoPedido) => {
        if (estadoPedido.includes('Vence en')) {
            return { color: '#6da3fc' };
        } else if (estadoPedido.includes('Vencido por')) {
            return { color: '#ec762d' };
        } else {
            return { color: '#000' }; 
        }
    };

    return (
        <div className='ordenCompra_conatiner'>
        <div className='ordenCompra_conatiner_h2'>
            <h2>Todas las órdenes de compra</h2>
            <div className='ordenCompra_conatiner_btn'>
                <button className='ordenCompra_conatiner_btn_nuevo'>
                    <IoAdd size={24} /> Nuevo
                </button>
                <button className='ordenCompra_conatiner_btn_opcion'>
                    <span style={{ fontSize: '20px' }}>⋮</span>
                </button>
            </div>
        </div>

        <div className='ordenCompra_conatiner_tabla_wrapper'>
            <table className='ordenCompra_conatiner_tabla'>
                <thead className='ordenCompra_tabla_title'>
                    <tr>
                        <th>FECHA</th>
                        <th>ORDEN DE COMPRA N°</th>
                        <th>N° DE REFERENCIA</th>
                        <th>NOMBRE DE PROVEEDOR</th>
                        <th>ESTADO</th>
                        <th>FACTURADA</th>
                        <th>PAGO</th>
                        <th>IMPORTE</th>
                        <th>FECHA DE ENVÍO ESPERADA</th>
                        <th>ESTADO DE PEDIDO</th>
                        <th>MÉTODO DE ENTREGA</th>
                        <th className="ordenCompra_tabla_title_col-2">
                            <CiSearch size={24} />
                        </th>
                    </tr>
                </thead>
                <tbody className='ordenCompra_tabla_title_tbody'>
                    {data.map((item, index) => (
                        <tr key={index} 
                            className='ordenCompra_tabla_fila'
                            onClick={() => handleRowClick(item)}>
                            <td>{item.fecha}</td>
                            <td style={{ color: '#6da3fc' }}>{item.orden_de_venta}</td>
                            <td>{item.numero_referencia}</td>
                            <td>{item.nombre_cliente}</td>
                            <td style={getEstadoColor(item.estado)}>{item.estado}</td>
                            <td>{item.facturada}</td>
                            <td>{item.pago}</td>
                            <td>{item.importe}</td>
                            <td>
                                {item.fecha_envio_esperada}<br />
                                <span style={getEstadoPedidoColor(item.estado_pedido)}>{item.estado_pedido}</span>
                            </td>
                            <td style={getEstadoColor(item.estado)}>{item.estado}</td>
                            <td>{item.metodo_entrega}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
        <div className='ordenCompra_pagination-container'>
            <div className='ordenCompra_pagination-container-select'>
                <SelectLimit onLimitChange={setLimit} setPage={setPage} currentLimit={limit}/>
                <Pagination totalPage={totalPage} page={page} limit={limit} 
                        siblings={1} onPageChange={handlePageChange} menu={false} />
            </div>
        </div>
        
    </div>
    )
}

