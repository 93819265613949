import React, { useState, useEffect } from 'react';
import { Layout, Menu, Button } from 'antd';
import { Link } from 'react-router-dom';
import {
    AlignLeftOutlined, BarsOutlined, ContainerOutlined, DatabaseOutlined, FileDoneOutlined,
    FileAddOutlined, FileTextOutlined, ShopOutlined, ShoppingOutlined, MenuFoldOutlined, MenuUnfoldOutlined
} from '@ant-design/icons';
import '../styles/customSidebar.css';

const { Sider } = Layout;

export const CustomSidebar = ({ ambiente }) => {
    const [collapsed, setCollapsed] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 500);

    // Función para alternar la visibilidad del Sider
    const toggleCollapsed = () => {
        if (!isMobile) {
            setCollapsed(!collapsed);
        }
    };

    // useEffect para detectar el tamaño de la pantalla
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 500);
        };

        window.addEventListener('resize', handleResize);

        // Cleanup function
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Forzar que el Sider esté colapsado si es un dispositivo móvil
    useEffect(() => {
        if (isMobile) {
            setCollapsed(true);
        }
    }, [isMobile]);

    const items = [
        {
            key: 'marketplaces',
            icon: <ShopOutlined />,
            label: 'Marketplaces',
            children: [
                { key: '1', label: <Link to="/store">Mis Tiendas</Link>, icon: <ShoppingOutlined /> },
                { key: '2', label: <Link to="/items">Artículos</Link>, icon: <BarsOutlined /> },
                { key: '3', label: <Link to="/webhooks">Webhooks</Link>, icon: <AlignLeftOutlined /> },
                { key: '4', label: <Link to="/extraUpdate">Extra Update</Link>, icon: <AlignLeftOutlined /> },
            ],
        },
    ];

    if (ambiente !== "DEVELOPER") {
        items.push(
            {
                key: 'inventory',
                icon: <DatabaseOutlined />,
                label: 'Inventario',
                children: [
                    { key: '5', label: <Link to="/new-article">Nuevo Artículo</Link>, icon: <FileAddOutlined /> },
                    { key: '6', label: <Link to="/active-articles">Artículos Activos</Link>, icon: <FileDoneOutlined /> },
                ],
            },
            {
                key: 'facturacion',
                icon: <FileTextOutlined />,
                label: 'Facturación',
                children: [
                    { key: '7', label: <Link to="/facturas">Facturas</Link>, icon: <FileDoneOutlined /> },
                    { key: '8', label: <Link to="/nueva-factura">Nueva Factura</Link>, icon: <FileAddOutlined /> },
                ],
            },
            {
                key: 'ordenesVentas',
                icon: <ContainerOutlined />,
                label: 'Órdenes de Venta',
                children: [
                    { key: '9', label: <Link to="/orden-venta">Órdenes de Venta</Link>, icon: <ContainerOutlined /> },
                    { key: '10', label: <Link to="/new-orden-venta">Nueva Orden de Venta</Link>, icon: <FileAddOutlined /> },
                ],
            },
            {
                key: 'ordenesCompra',
                icon: <ContainerOutlined />,
                label: 'Órdenes de Compra',
                children: [
                    { key: '11', label: <Link to="/orden-compra">Órdenes de Compra</Link>, icon: <ContainerOutlined /> },
                    { key: '12', label: <Link to="/new-orden-compra">Nueva Orden de Compra</Link>, icon: <FileAddOutlined /> },
                ],
            }
        );
    }

    return (
        <Sider 
            className="site-layout-background" 
            style={{ 
                marginTop: '40px', 
                transition: 'width 0.3s', 
                overflow: 'hidden',
            }}
            width={collapsed ? 80 : 200} 
        >
            <Button 
                type="primary" 
                onClick={toggleCollapsed} 
                style={{ margin: '16px', backgroundColor: 'var(--secondary-color)' }}
                disabled={isMobile} 
            >
                {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            </Button>
            <Menu 
                defaultSelectedKeys={['1']} 
                mode="inline" 
                theme="dark" 
                inlineCollapsed={collapsed} 
                items={items} 
            />
        </Sider>
    );
};
