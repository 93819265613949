import { facturasData } from '../json/factutas';
import logo from '../img/Logo ei3 original.jpg';

export const tempContainer = (factura) =>{
    const container = document.createElement('div');
        container.style.width = '210mm';
        container.style.height = 'auto';
        container.style.padding = '18mm';
        container.style.backgroundColor = '#fff';
        container.style.position = 'absolute';
        container.style.top = '0px';
        container.style.left = '0px'; 
        container.style.marginBottom = '50px';
        container.style.fontFamily = 'Arial, sans-serif';
        container.style.border = '1px solid #afacac'
        container.innerHTML = `
    
    <div style="display: flex; justify-content: space-between; align-items: flex-start; margin-bottom: 20px;">
        <div style="display: flex; flex-direction: column; align-items: flex-start;">
            <img style="width: 100px;" src="${logo}" alt="Logo EI3">
            <p style="margin: 0; font-size: 20px; color: #015666;">Evolución i3</p>
            <p style="margin: 0; font-size: 16px;">${factura.localidad}</p>
            <p style="margin: 0; font-size: 16px;">${factura.pais}</p>
        </div>

        <div style="display: flex; flex-direction: column; align-items: flex-end;">
            <h1 style="font-size: 28px; color: #015666; margin: 0;">Factura</h1>
            <p style="margin: 0;"><strong>#</strong> ${factura.numero_factura}</p>
            <strong style="margin-top: 20px" >Saldo adeudado</strong>
            <p style="margin: 0; font-size: 20px; font-weight: 600;"> ${factura.saldo_adeudado}</p>
        </div>
    </div>

    <div style="display: flex; justify-content: space-between; align-items: flex-start; margin-bottom: 20px;">
        <div style="display: flex; flex-direction: column; align-items: flex-start;">
            <p style="margin: 0; font-size: 18px; font-weight: 600;">Cobrar a</p>
            <p style="margin: 0; font-size: 16px; color: #408dfb;">${factura.nombre_cliente}</p>
            <p style="margin: 0; font-size: 16px;">${factura.direccion}</p>
            <p style="margin: 0; font-size: 16px;">${factura.provincia}</p>
            <p style="margin: 0; font-size: 16px;">${factura.localidad}</p>
            <p style="margin: 0; font-size: 16px;">${factura.pais}</p>  
            <p style="margin: 0; font-size: 16px;">${factura.email}</p>  
            <p style="margin: 0; font-size: 16px;">RFC: ${factura.RFC}</p>  
        </div>

        <div style="display: grid; grid-template-rows: 1fr 1fr; justify-items: end;">
            
            <div style="display: flex; flex-direction: row;">
                <p style="margin-top: 30px; margin-right: 10px; font-weight: 600;">Fecha de Factura: </p>
                <p style="margin-top: 30px; ">${factura.fecha_factura}</p>
            </div>
            <div style="display: flex; flex-direction: row;">
                <p style="margin-top: 20px; margin-right: 10px; font-weight: 600;">Términos: </p>
                <p style="margin-top: 20px;">${factura.terminos}</p>
            </div>
            <div style="display: flex; flex-direction: row;">
                <p style="margin-top: 10px; margin-right: 10px; font-weight: 600;">Fecha de Vencimiento: </p>
                <p style="margin-top: 10px;">${factura.fecha_vencimiento}</p>
            </div>

        </div>
    </div>

    <table style=" width: 100%; margin-top: 20px; table-layout: fixed;">
        <thead>
            <tr style="height: 32px; font-size: 9px; color: #ffffff; background-color: #015666;">
                <td style="font-size: 14px; padding: 5px 10px 5px 20px; width: 70%; text-align: left;">Producto & Descripción</td>
                <td style="font-size: 14px; padding: 5px 10px 5px 5px; width: 11%; text-align: right;">Cantidad</td>
                <td style="font-size: 14px; padding: 5px 10px 5px 5px; width: 15%; text-align: right;">Precio</td>
                <td style="font-size: 14px; padding: 5px 10px 5px 5px; width: 15%; text-align: right;">Importe</td>
            </tr>
        </thead>
        <tbody>
        ${factura.detalle_del_articulo.map(info => `
            <tr style="height: 32px; font-size: 9px;">
                <td style="font-size: 14px; padding: 10px 10px 5px 20px; text-align: left;">${info.name}</td>
                <td style="font-size: 14px; padding: 10px 10px 5px 5px; text-align: right;">${info.cantidad}</td>
                <td style="font-size: 14px; padding: 10px 10px 5px 5px; text-align: right;">${info.precio}</td>
                <td style="font-size: 14px; padding: 10px 10px 5px 5px; text-align: right;">${info.importe}</td>
            </tr>
        `).join('')}

        </tbody>
    </table>

    <hr/>

    <div style="display: grid; grid-template-rows: 1fr 1fr; justify-items: end;">
            
        <div style="display: flex; flex-direction: row;">
            <p style="color: #015666; margin-top: 30px; margin-right: 10px;">Sub Total </p>
            <p style="color: #015666; margin-top: 30px; ">${factura.sub_total}</p>
        </div>
        <div style="display: flex; flex-direction: row;">
            <p style="color: #015666; margin-top: 20px; margin-right: 10px; font-weight: 600;">Total </p>
            <p style="color: #015666; margin-top: 20px; font-weight: 600;">${factura.total}</p>
        </div>
        <div style="display: flex; flex-direction: row;">
            <p style="color: #015666; margin-top: 10px; margin-right: 10px; font-weight: 600;">Saldo adeudado </p>
            <p style="color: #015666; margin-top: 10px; font-weight: 600;">${factura.saldo_adeudado}</p>
        </div>
    </div>

    <div style="display: flex; flex-direction: column; align-items: flex-start;width: 100%; ">
        <h3 style="margin-top: 20px; font-size: 14px; font-weight: 600;">Términos y condiciones</h3>
        <p style="margin-top: 10px; font-size: 12px; white-space: pre-wrap; white-space: pre-line;">
                PRODUCTOS Y SERVICIOS VENDIDOS ESTAN EXPRESAMENTE SUJETOS A LOS TÉRMINOS Y
                CONDICIONES ESTABLECIDOS EN EL PRESENTE Y EN EL SITIO WEB DE EVOLUCIONI3,
                WWW.EVOVUCIONI3.COM, SIN PERJUICIO DE CUALQUIER TERMINO CONTRADICTORIO DE LA ORDEN DE
                COMPRA DEL CLIENTE. EN CASO DE CONFLICTO ENTRE LOS TÉRMINOS Y CONDICIONES DE
                VENTA ESTABLECIDOS AQUI Y EN LOS ESTABLECIDOS EN EL SITIO WEB DE EVCOL, LOS
                TÉRMINOS Y CONDICIONES DE VENTA QUE SE ESTABLECEN EN EL SITIO WEB EVOLUCIONi3
                PREVALECERÁN. NINGUNA PERSONA TIENE AUTORIDAD PARA ALTERAR LOS TÉRMINOS
                EXCEPTO UN OFICIAL CORPORATIVO DE EVOLUCIONI3, Y CUALQUIER ALTERACIÓN DEBE SER
                POR ESCRITO. LA ACEPTACIÓN DEL COMPRADOR DE PRODUCTOS CONSTITUYE LA
                ACEPTACIÓN DE ESTOS TÉRMINOS Y CONDICIONES DE LA VENTA
        </p>
    </div>
    
    
    `;
    return container;
};


