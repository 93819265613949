import React, {createContext, useState, useEffect} from "react";
import axios from 'axios';

export const DataContext = createContext({});

export const DataProveder = ({children}) => {

    const ambiente = process.env.REACT_APP_AMBIENTE;

    //todas las tiendas
    const [allStores, setAllStores] = useState(null);
    const [storaName, setStoraName] = useState(null);


    // Obtener OrganizationID del localStorage
    const organizationID = localStorage.getItem('organizationID');
    
    // tengo todas las tiendas
    const getStores = async () => {
        if (organizationID) {
            try { 
                const res = await axios.get(`https://facturacionei3.com/ep_StoreEcart?OrganizationID=${organizationID}`);
                if (res.status === 200) {
                    setAllStores(res.data);  
                } 
            } catch (error) {
                console.log('Error al realizar la solicitud:', error);
                console.log(null);  
            }
        } 
    };

     // Función que mapea todas las tiendas por su nombre
    useEffect(() => {
        if (allStores) {
            const storeNames = allStores.map((store) => ({ name: store.Ecommerce }));
            setStoraName(storeNames);
        }
    }, [allStores]);

    useEffect(() => {
        getStores();
        
    }, []);

    




    return(
        <DataContext.Provider value={{allStores,storaName, ambiente}}>
            {children}
        </DataContext.Provider>
    )

}